.sidebar {
  position: relative;
  background: var(--color-blue);
  width: 100%;
  height: 100%;
  min-height: 600px;
  padding: 25px 10px 60px 52px;
  border-radius: 0px 0px 26px 0px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #fff;
}

.sidebar_content {
  margin-top: 73px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.sidebar_row{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sidebar_search {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 58px;
  max-width: 266px;
  width: 100%;
  padding: 15px 11px 17px 51px;
  background: #F1F2F2 0% 0% no-repeat padding-box;
  text-align: left;
  font: normal normal normal 22px/27px Informa Pro;
  letter-spacing: 0px;
}

.list_type_name {
  text-align: left;
  font: normal normal medium 35px/53px Poppins;
  font-size: 35px;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #E6E7E8;
}

.charity_type_list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  row-gap: 20px;
}

/********************** Media Queries **********************/

@media (max-width: 1600px) {
  .sidebar {
    padding: 0px 20px 25px 30px;
  }
  .sidebar_content {
    margin-top: 50px;
  }
  .list_type_name {
    font-size: 30px;
  }
  .sidebar_search {
    font-size: 20px;
  }
}


@media (max-width: 1440px) {
  .sidebar {
    padding: 0px 20px 25px 30px;
  }
  .sidebar_content {
    margin-top: 50px;
  }
  .list_type_name {
    font-size: 30px;
  }
  .sidebar_search {
    font-size: 20px;
  }
}

@media (max-width: 1367px) {
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
  .sidebar {
    display: none;
  }
}

@media (max-width: 767px) {
}

@media (max-width: 585px) {
}

@media (max-width: 480px) {
}