@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  /* Colors: */
  --container-width: 1920px;
  --content-width: 1640px;
  --color-white-1: #f1f2f2;
  --color-white-2: #e6e7e8;
  --color-green: #6bc17f;
  --color-blue: #144460;
  --color-blue-2: #21445e;
  --color-gray: #707070;

  /* Font/text values */
  --unnamed-font-family-poppins: Poppins;
  --unnamed-font-family-informa-pro: Informa Pro;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-22: 22px;
  --unnamed-font-size-25: 25px;
  --unnamed-font-size-30: 30px;
  --unnamed-font-size-35: 35px;
  --unnamed-font-size-65: 65px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-27: 27px;
  --unnamed-line-spacing-30: 30px;
  --unnamed-line-spacing-38: 38px;
  --unnamed-line-spacing-43: 43px;
  --unnamed-line-spacing-46: 46px;
  --unnamed-line-spacing-53: 53px;
  --unnamed-line-spacing-98: 98px;
}

/****************************** Globals Styles ******************************/

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background: #fff;
}

ol,
ul {
  list-style: none;
}

a {
  color: inherit;
  /* blue colors for links too */
  text-decoration: none;
  /* no underline */
}

button {
  text-decoration: none;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  background: #6bc17f 0% 0% no-repeat padding-box;
  color: var(--color-blue);
  box-shadow: 3px 3px 6px #00000029;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #fff;
  transition: 0.3s ease all;
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

/* Character Styles */
.h1 {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-65);
  line-height: var(--unnamed-line-spacing-98);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--color-blue);
}

.h2 {
  font-family: var(--unnamed-font-family-informa-pro);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-35);
  line-height: var(--unnamed-line-spacing-43);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--color-blue-2);
}

.h3 {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-35);
  line-height: var(--unnamed-line-spacing-53);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--color-blue);
}

.h4 {
  font-family: var(--unnamed-font-family-poppins);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-25);
  line-height: var(--unnamed-line-spacing-38);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--color-blue);
}

.container {
  max-width: var(--container-width);
  width: 100%;
  margin: 0 auto;
}

.content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.msg-error {
  color: #d32f2f;
}

.text-bold{
  font-weight: 600;
}

/********************** React Select **********************/

#react-select-3-input {
  min-height: 30px !important;
}

/********************** React Multi Select **********************/

.rmsc .dropdown-container {
  min-height: 46px;
  outline: none !important;
  border: none !important;
  border-radius: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rmsc .dropdown-heading .dropdown-heading-value {
  padding-left: 30px;
}

.rmsc .gray {
  color: #707070 !important;
}

.dropdown-heading-dropdown-arrow.gray {
  color: var(--color-blue) !important;
  margin-right: 11px;
}

/********************** Media Queries **********************/

@media (max-width: 1367px) {}

@media (max-width: 1200px) {}

@media (max-width: 991px) {}

@media (max-width: 767px) {}

@media (max-width: 585px) {}

@media (max-width: 480px) {}