:root {
    /* Colors: */
    --container-width: 1920px;
    --content-width: 1640px;
    --color-white-1: #f1f2f2;
    --color-white-2: #e6e7e8;
    --color-green: #6bc17f;
    --color-blue: #144460;
    --color-blue-2: #21445e;
    --color-gray: #707070;

    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-medium: medium;
    --unnamed-font-weight-bold: bold;
    --unnamed-font-weight-normal: normal;
}

/********************************** Navbar **********************************/
.navbar {
  width: 100%;
  height: 150px;
  padding: 0px 40px;
  color: var(--color-gray);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 100;
}
.navbar .btn-menu{
  display: none;
  font-size: 2rem;
  color: var(--color-blue);
  cursor: pointer;
}
.navbar .nav-container {
  max-width: var(--content-width);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
}
.navbar .nav-container .cont-logo {
  display: flex;
  flex-direction: column;
}
.navbar .nav-container .cont-logo .logo {
  width: 182px;
}
.navbar .nav-container .cont-logo .credits {
  text-align: right;
  font: normal normal normal 18px/24px Informa Pro;
  letter-spacing: 0px;
  color: var(--color-gray);
}
.navbar .nav-container .nav-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 38px;
  font-weight: 500;
}
.navbar .nav-container .nav-menu .nav-item {
  text-align: center;
  font-size: 20px;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: var(--color-gray);
}
.navbar .nav-container .nav-menu .btn-take-quiz {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  height: 37px;
  padding: 0px 20px;
  background: #6bc17f 0% 0% no-repeat padding-box;
  color: var(--color-blue);
  border-radius: 7px;
  text-align: center;
  font-size: 18px;
  font-weight: var(--unnamed-font-weight-bold);
  letter-spacing: 0px;
  box-shadow: 3px 3px 6px #00000029;
}

/********************** Media Queries **********************/

@media (max-width: 1440px) {
  .navbar .nav-container {
    max-width: 1440px;
  }
  
}

@media (max-width: 1367px) {
  .navbar .nav-container {
    max-width: 1200px;
  }
}

@media (max-width: 1200px) {
  .navbar .nav-container {
    max-width: 1100px;
  }
}

@media (max-width: 991px) {
  .navbar {
    height: 100px;
  }
  .navbar .btn-menu{
    display: block;
  }
  .navbar .nav-container .nav-menu {
    padding: 10px 0px 20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 10000;
  }
  .navbar .nav-container .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    background: #fff;

  }
  .navbar .nav-container .nav-menu .nav-item {
    width: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navbar .nav-container .nav-menu .nav-item .nav-links {
    text-align: center;
    padding: 20px;
    width: 100%;
    display: table;
    font-size: 20px;
  }
  .navbar .nav-container .nav-menu .nav-item .nav-links:hover {
    background-color: var(--color-blue);
    color: var(--color-white-1);
    border-radius: 0;
    transition: all 0.2s ease-out;
  }
  .navbar .nav-container .nav-menu .btn-take-quiz {
    width: 100%;
    padding: 25px 0px;
    font-size: 20px;
  }

}

@media (max-width: 767px) {
  .navbar .nav-container .nav-menu {
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0px 20px;
  }
  .navbar .nav-container .nav-menu .btn-take-quiz,
  .navbar .nav-container .nav-menu .nav-item .nav-links {
    font-size: 16px;
  }
}