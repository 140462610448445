.sec_change_world {
    width: 100%;
    /* min-height: 1498px; */
    min-height: 800px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    background: #f1f2f2 0% 0% no-repeat padding-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
}

.content {
    max-width: var(--content-width);
    width: 100%;
}

.title {
    text-align: center;
    /* font: normal normal medium 65px/98px Poppins; */
    font-size: 45px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #144460;
}

.cont_cards {
    margin-top: 42px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .title {
        font-size: 45px;
    }
    .cont_cards {
        gap: 40px;
    }
}

@media (max-width: 1367px) {
    .title {
        font-size: 40px;
    }
    .cont_cards {
        gap: 50px;
    }
    .sec_change_world {
        /* min-height: 1200px; */
        min-height: 800px;
    }
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
    .title {
        font-size: 35px;
    }
}

@media (max-width: 767px) {
    .title {
        font-size: 35px;
    }
    .cont_cards {
        gap: 30px;
    }
}

@media (max-width: 585px) {
    .title {
        font-size: 26px;
    }
}

@media (max-width: 480px) {
    .sec_change_world {
        padding: 60px 10px;
    }
    
}