.results{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.charity_list {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.question {
    color: var(--color-blue);
    text-align: center;
    font: normal normal medium 65px/98px Poppins;
    font-weight: var(--unnamed-font-weight-medium);
    font-size: 50px;
    letter-spacing: 0px;
}

.btn_retake {
    margin-top: 50px;
    cursor: pointer;
    text-align: center;
    color: var(--color-green);
    font-size: 18px;
    font-weight: 700;
    border-radius: 10px;

    text-align: center;
    font: normal normal medium 25px/38px Poppins;
    font-size: 25px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
}


/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .charity_list {
        margin: 60px 0px;
        gap: 25px;
    }
    .btn_retake {
        margin-top: 0px;
        font: normal normal medium 22px/35px Poppins;
        font-size: 22px;
    }
}

@media (max-width: 1367px) {}

@media (max-width: 1200px) {}

@media (max-width: 991px) {
    .charity_list {
        margin: 60px 0px;
    }
}

@media (max-width: 767px) {
    .charity_list {
        margin: 40px 0px;
    }
}

@media (max-width: 585px) {
    .btn_retake {
        font: normal normal medium 22px/35px Poppins;
        font-size: 18px;
    }
}

@media (max-width: 480px) {}