.item {
  display: flex;
  flex-direction: column;
}

.list_type_name{
  text-align: left;
  font: normal normal medium 35px/53px Poppins;
  font-size: 35px;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #E6E7E8;
}

.charity_type_list {
  display: flex;
  flex-direction: column;
}

.dropdown_title{
  text-align: left;
  font: normal normal medium 25px/38px Poppins;
  font-size: 25px;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #E6E7E8;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.dropdown_item{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font: normal normal normal 25px/30px Informa Pro;
  letter-spacing: 0px;
  color: #E6E7E8;
  padding-left: 25px;
}

.check_item{
  cursor: pointer;
}

/********************** Media Queries **********************/

@media (max-width: 1440px) {
  .list_type_name{
    font-size: 30px;
  }
  .dropdown_title{
    font-size: 22px;
  }
  .dropdown_item{
    font-size: 18px;
  }
}

@media (max-width: 1367px) {
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
}

@media (max-width: 585px) {
}

@media (max-width: 480px) {
}