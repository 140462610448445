.row {
    width: 100%;
    min-height: 141px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 413px;
}
.content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
}

@media (max-width: 1600px) {
    .row {
        padding-left: 350px;
    }
}

@media (max-width: 1440px) {
    .row {
        padding-left: 300px;
    }
}

@media (max-width: 991px) {
    .row {
        display: flex;
        flex-direction: column;
        padding-left: 0px;
    }
}