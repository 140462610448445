/********************************** Questinnaire **********************************/
.quiz {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 20px;
    background: #F1F2F2 0% 0% no-repeat padding-box;
}

.quiz .content{
    max-width: var(--content-width);
    width: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.quiz .question {
    color: var(--color-blue);
    text-align: center;
    font: normal normal medium 65px/98px Poppins;
    font-weight: var(--unnamed-font-weight-medium);
    font-size: 50px;
    letter-spacing: 0px;
}

.text_apply{
    color: var(--color-blue);
    text-align: center;
    font: normal normal medium 65px/98px Poppins;
    font-weight: var(--unnamed-font-weight-medium);
    margin-top: 30px;
    font-size: 45px;
}

.quiz .btn-quiz {
    width: 226px;
    height: 60px;

    font-weight: 700;
    font-size: 18px;
    background: #6BC17F 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 15px;
    
    text-align: center;
    font: normal normal bold 30px/46px Poppins;
    letter-spacing: 0px;
    color: #144460;
}

.quiz .cont_actions{
    margin-top: 66px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.quiz .btn-quiz:hover {
    background: #fff;
}

.quiz .multiple-options {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 52px;
}

.quiz .multiple-options .option {
    max-width: 307px;
    width: 100%;
    min-height: 98px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 24px;

    text-align: center;
    font: normal normal medium 30px/46px Poppins;
    font-size: 30px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #144460;
    cursor: pointer;
}

/* .quiz .multiple-options .option:hover, */
.quiz .multiple-options .option.active {
    background-color: var(--color-green);
    transition: 0.3s ease all;
}


/********************** Media Queries **********************/

@media (max-width: 1800px) {
    .quiz .question {
        font-size: 50px;
    }
    .text_apply{
        font-size: 30px;
    }
}
@media (max-width: 1440px) {
    .text_apply{
        font-size: 28px;
    }
    .quiz .card-list {
        column-gap: 40px;
        row-gap: 30px;
    }
    .quiz .multiple-options {
        gap: 40px;
    }
    .quiz .multiple-options .option {
        min-height: 80px;
        font-size: 22px;
    }
}

@media (max-width: 1367px) {
    .quiz .question {
        font-size: 40px;
    }
    .text_apply{
        font-size: 26px;
    }
    .quiz .card-list .card .card-content .card-title {
        font-size: 30px;        
    }
    .quiz .card-list {
        margin-top: 60px;
        gap: 30px;
    }
    .quiz .card-list .card {
        min-height: 400px;
        max-width: 300px;
        width: 100%;
    }
    .quiz .card-list .card .card-img {
        height: 250px;
        width: 100%;
    }
    .quiz .btn-quiz,
    .quiz .multiple-options .option {
        font: normal normal medium 30px/46px Poppins;
        font-size: 22px;
    }
    .quiz .multiple-options {
        gap: 30px;
    }
}

@media (max-width: 1200px) {
    .quiz .question {
        font-size: 35px;
    }
    .text_apply{
        font-size: 25px;
    }
}

@media (max-width: 991px) {
    
}

@media (max-width: 767px) {
    .quiz .question {
        font-size: 35px;
    }
    .text_apply{
        margin-top: 30px;
        font-size: 22px;
    }
    .quiz .multiple-options {
        gap: 20px;
    }
    .quiz .multiple-options .option{
        height: 46px;
        min-height: auto;
        font: normal normal bold 17px/26px Poppins;
        font-size: 17px;
    }
    .quiz .cont_actions{
        flex-direction: column-reverse;
        gap: 40px;
    }
}

@media (max-width: 585px) {
    .quiz .question {
        font-size: 30px;
    }
    .text_apply{
        margin-top: 30px;
        font-size: 20px;
    }
    .quiz .card-list .card .card-content .card-title {
        font-size: 25px;        
    }
    .quiz .multiple-options {
        gap: 25px;
    }
    .quiz .btn-quiz{
        font: normal normal bold 19px/29px Poppins;
        font-size: 19px;
        height: 48px;
        max-width: 100%;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .quiz .question {
        font-size: 25px;
    }
    .text_apply{
        margin-top: 30px;
        font-size: 18px;
    }
    .quiz .btn-quiz{
        max-width: 100%;
        width: 100%;
    }
}