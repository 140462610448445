/********************************** Find Your Charity **********************************/
.container{
    background: var(--color-white-1);
}

.view {
    position: relative;
    display: grid;
    grid-template-columns: 413px 1fr;
}

.content {
    position: relative;
    width: 100%;
    min-height: 717px;
}

.head_content{
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 49px 20px 0px 20px;
}

.content_center{
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.title {
    text-align: left;
    font: normal normal medium 65px/78px Filson Soft;
    font-family: Filson Soft;
    font-size: 50px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #FFFFFF;
}

.description {
    position: relative;
    z-index: 2;
    text-align: left;
    font: normal normal normal 35px/43px Informa Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.btn_mobile_filter{
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    font: normal normal bold 19px/29px Poppins;
    font-size: 18px;
    letter-spacing: 0px;
    color: #144460;
    background: #6BC17F 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 10px;
    max-width: 382px;
    width: 100%;
    min-height: 60px;
    gap: 10px;
    z-index: 2;
}

.bg_overlay {
    position: absolute;
    width: 100%;
    height: 717px;
    z-index: 1;
    background-size: cover;
}

.results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.charity_list {
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 25px;
    column-gap: 25px;
    padding: 20px 20px;
}


/********************** Media Queries **********************/

@media (max-width: 1600px) {
    .view {
        grid-template-columns: 350px 1fr;
    }
    .head_content{ 
        margin: 40px 20px 0px 20px;
    }
    .title {
        font-size: 55px;
    }

    .description {
        font-size: 30px;
    }
}

@media (max-width: 1440px) {
    .view {
        grid-template-columns: 300px 1fr;
    }
    .title {
        font-size: 55px;
    }
    .description {
        font-size: 28px;
    }
}

@media (max-width: 1367px) {
    .title {
        font-size: 50px;
    }
    .description {
        font-size: 25px;
    }
}

@media (max-width: 1200px) {
    .title {
        font-size: 45px;
    }
}

@media (max-width: 991px) {
    .view {
        display: flex;
        flex-direction: column;
    }
    .head_content{ 
        margin: 40px 20px 40px 20px;
    }
    .title {
        font-size: 40px;
    }
    .btn_mobile_filter{
        display: flex;
    }
}

@media (max-width: 767px) {
    .title {
        font-size: 35px;
    }
}

@media (max-width: 585px) {
    .title {
        font-size: 30px;
    }
}

@media (max-width: 480px) {}