:root {
  /* Colors: */
  --container-width: 1920px;
  --content-width: 1640px;
  --color-white-1: #f1f2f2;
  --color-white-2: #e6e7e8;
  --color-green: #6bc17f;
  --color-blue: #144460;
  --color-blue-2: #21445e;
  --color-gray: #707070;

  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-weight-normal: normal;
}

/********************************** Mission **********************************/

.mission .intro {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 40px;
}
.mission .intro .content {
  margin-top: 134px;
  max-width: 1361px;
  width: 100%;
  z-index: 2;
}
.mission .intro .content .title {
  text-align: left;
  font-family: Filson Soft;
  font-size: 50px;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #ffffff;
}
.mission .intro .content .description {
  margin-top: 49px;
  max-width: 707px;
  width: 100%;
  text-align: left;
  font: normal normal normal 26px/43px Informa Pro;
  letter-spacing: 0px;
  color: #ffffff;
}

.mission .sec-floor-pledge {
  width: 100%;
  min-height: 882px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
.mission .sec-floor-pledge .content {
  display: flex;
  flex-direction: column;
}
.mission .sec-floor-pledge .content .title {
  text-align: center;
  font-size: 50px;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #144460;
}
.mission .sec-floor-pledge .content .description {
  max-width: 1180px;
  width: 100%;
  margin-top: 75px;
  text-align: center;
  font: normal normal normal 26px/43px Informa Pro;
  letter-spacing: 0px;
  color: #21445e;
  opacity: 1;
}

.mission .sec-list-charities {
  max-width: 100%;
  width: 100%;
  min-height: 1000px;
  background: #e6e7e8 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: flex;
  justify-content: center;
  padding: 40px;
}
.mission .sec-list-charities .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.mission .sec-list-charities .content .title {
  margin-top: 129px;
  text-align: center;
  /* font: normal normal medium 65px/98px Poppins; */
  font-size: 50px;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #144460;
}
.mission .sec-list-charities .content .description {
  margin-top: 75px;
  max-width: 1180px;
  text-align: center;
  font: normal normal normal 26px/43px Informa Pro;
  letter-spacing: 0px;
  color: #21445e;
}

.mission .brand-link{
  color: #6bc17f;
}

/********************** Media Queries **********************/

@media (max-width: 1367px) {
}

@media (max-width: 1200px) {
  .mission .sec-floor-pledge,
  .mission .sec-list-charities {
    justify-content: center;
    align-items: center;
    padding: 60px 40px;
  }
  .mission .intro .content .title,
  .mission .sec-floor-pledge .content .title,
  .mission .sec-list-charities .content .title {
    margin-top: 0px;
    font-size: 50px;
  }
  .mission .intro .content .description,
  .mission .sec-floor-pledge .content .description,
  .mission .sec-list-charities .content .description {
    font: normal normal normal 24px/43px Informa Pro;
  }
  .mission .sec-list-charities {
    min-height: 882px;
  }
}

@media (max-width: 991px) {
  .mission .intro,
  .mission .sec-floor-pledge,
  .mission .sec-list-charities {
    justify-content: center;
    align-items: center;
    padding: 60px 40px;
  }
  .mission .intro .content {
    margin-top: 0px;
  }
  .mission .sec-floor-pledge .content .title {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .mission .intro .content .title,
  .mission .sec-floor-pledge .content .title,
  .mission .sec-list-charities .content .title {
    margin-top: 0px;
    font-size: 32px;
  }
  .mission .intro .content .description,
  .mission .sec-floor-pledge .content .description,
  .mission .sec-list-charities .content .description {
    font: normal normal normal 20px/43px Informa Pro;
  }
}

@media (max-width: 580px) {
  .mission .intro,
  .mission .sec-floor-pledge,
  .mission .sec-list-charities {
    padding: 60px 20px;
  }
}
