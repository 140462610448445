.charity_card {
    max-width: 1181px;
    width: 100%;
    min-height: 283px;
    display: grid;
    grid-template-columns: 283px 1fr 259px;
    border-radius: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 6px #00000029;
    overflow: hidden;
}

.card_img {
    background: #dedede;
}

.card_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* row-gap: 10px; */
    padding: 10px 0px 10px 20px;
}

.card_title {
    text-align: left;
    /* font: normal normal medium 35px/53px Poppins; */
    font-size: 35px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: var(--color-blue);
}

.location {
    text-align: left;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: var(--color-gray);
}

.card_description {
    max-width: 651px;
    width: 100%;
    min-height: 155px;
    text-align: left;
    font: normal normal normal 25px/30px Informa Pro;
    letter-spacing: 0px;
    color: var(--color-blue);
    /* overflow: hidden; */
    padding: 15px 0px;
}

.card_actions {
    padding: 0px 45px 0px 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 38px;
}

.btn_action {
    max-width: 183px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background-color: var(--color-green);
    color: var(--color-blue);
    box-shadow: 3px 3px 6px #00000029;
    text-align: center;
    font: normal normal bold 25px/38px Poppins;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_action:hover {
    background-color: #fff;
    transition: 0.3s ease all;
}


/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .charity_card {
        max-width: 925px;
        min-height: 200px;
        display: grid;
        grid-template-columns: 200px 1fr 200px;
    }
    .card_title {
        text-align: left;
        font-size: 25px;
    }
    .card_description {
        margin-top: 17px;
        max-width: 512px;
        width: 100%;
        min-height: 108px;
        font: normal normal normal 20px/22px Informa Pro;
    }
    .btn_action {
        max-width: 165px;
        width: 100%;
        height: 40px;
        font: normal normal bold 20px/38px Poppins;
    }
}

@media (max-width: 1200px) {}

@media (max-width: 991px) {}

@media (max-width: 767px) {
    .charity_card {
        max-width: 1181px;
        width: 100%;
        min-height: 283px;
        grid-template-columns: 1fr 1fr;
    }
    .card_img {
        order: 1;
        background: #DEDEDE 0% 0% no-repeat padding-box;
        border-radius: 0px 26px 26px 0px;
        width: 100%;
        height: 141px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
    }
    .card_actions {
        order: 2;
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 1;
    }
    .card_content {
        width: 100%;
        max-width: auto;
        order: 3;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
    }
    .card_title {
        text-align: center;
    }
    .card_description {
        margin-top: 10px;
        font: normal normal normal 16px/22px Informa Pro;
    }
    .card_actions {
        padding: 20px;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
}

@media (max-width: 585px) {
    
    .btn_action {
        width: 100%;
        font: normal normal bold 18px/38px Poppins;
    }
}

@media (max-width: 480px) {
    .card_content {
        padding: 10px 20px;
    }
}