.card {
    max-width: 398px;
    width: 100%;
    min-height: 511px;
    background: #F1F2F2 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 6px #00000029;
    border-radius: 26px;
    overflow: hidden;
}
.card:hover,
.card.active {
    background-color: var(--color-green);
    transition: 0.3s ease all;
    cursor: pointer;
}
.card_img {
    height: 332px;
    width: 100%;
    background-color: gray;
    color: var(--color-blue);
}
.card_content {
    min-height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card_title {
    color: var(--color-blue);
    text-align: center;
    font: normal normal medium 35px/53px Poppins;
    font-size: 35px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
}
.card_actions {
    display: flex;
    justify-content: center;
    align-items: center;
}


/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .card_list {
        column-gap: 40px;
        row-gap: 30px;
    }
}

@media (max-width: 1367px) {
    .card_title {
        font-size: 30px;        
    }
    .card_list {
        margin-top: 60px;
        gap: 30px;
    }
    .card {
        min-height: 400px;
        max-width: 300px;
        width: 100%;
    }
    .card_img {
        height: 250px;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .card {
        min-height: auto;
    }
    .card_img{
        height: 123px;
    }
    .card_content {
        min-height: 110px;
    }
    .card_title{
        font: normal normal medium 20px/30px Poppins;
        font-size: 20px;
    }
}


@media (max-width: 585px) {
    .card_title {
        font-size: 28px;        
    }
}
