.testimonial {
    display: grid;
    grid-template-columns: 57px 276px;
    column-gap: 16px;
}

.content {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
}

.name {
    text-align: left;
    /* font: normal normal normal 30px/46px Poppins; */
    font-size: 30px;
    font-weight: var(--unnamed-font-weight-normal);
    letter-spacing: 0px;
    color: #21445e;
}

.description {
    text-align: left;
    /* font: normal normal medium 20px/30px Poppins; */
    font-size: 20px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #707070;
}

/********************** Media Queries **********************/

@media (max-width: 1640px) {
    
}

@media (max-width: 1440px) {
    
}

@media (max-width: 1367px) {
    .name {
        font-size: 25px;
    }
    .description {
        font-size: 18px;
    }
}

@media (max-width: 1200px) {}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
    
}

@media (max-width: 585px) {
    .name {
        font-size: 22px;
    }
    .description {
        font-size: 17px;
    }
}

@media (max-width: 480px) {
    .testimonial {
        grid-template-columns: 57px 1fr;
    }
    .name {
        font-size: 20px;
    }
    .description {
        font-size: 16px;
    }
}