.modal{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background: rgba(0,0,0, 0.75);
    display: none;
    justify-content: center;
    align-items: center;
    padding: 19px;
}
.modal.show{
    display: flex;
}
.modal_container{
    padding: 1rem;
    position: relative;
    width: 100%;
    min-height: 366px;
    background: #144460 0% 0% no-repeat padding-box;
    border-radius: 36px;
    max-width: 451px;
    max-height: 100vh;
    overflow-y: auto;
    
}

@media (min-width: 991px){
    .modal,
    .modal.show{
        display: none;
    }
}

@media (max-width: 480px){
    .modal{
        padding: 0px;
    }
}
