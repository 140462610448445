.intro{
    width: 100%;
    background: #E6E7E8 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.intro_content {
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 135px;
    padding: 10px 20px 60px 20px;
    gap: 40px;
}

.content {
    width: 100%;
    min-height: 375px;
}

.content:nth-child(1) {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.cont_left {
    display: flex;
    flex-direction: column;
}

.title {
    text-align: left;
    font-size: 50px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #144460;
}

.description {
    margin-top: 41px;
    text-align: left;
    font: normal normal normal 26px/43px Informa Pro;
    letter-spacing: 0px;
    color: #21445e;
}

.btn_quiz {
    margin-top: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 414px;
    width: 100%;
    height: 69px;
    background: #6bc17f 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 15px;
    text-align: center;
    font: normal normal bold 26px/40px Poppins;
    letter-spacing: 0px;
    color: #144460;
    transition: all 0.3s ease;
}

.btn_quiz:hover {
    background-color: #fff;
}

.intro_img {
    max-width: 748px;
    width: 100%;
    min-height: 375px;
}

.cont_search {
    margin-top: 14.25px;
    max-width: 414px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.link_search {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: left;
    font: normal normal medium 26px/38px Poppins;
    font-size: 24px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #6bc17f;
}

/********************** Media Queries **********************/

@media (max-width: 1640px) {
    .title {
        font-size: 50px;
    }
    .content {
        padding: 0px 20px;
    }
    .description {
        font: normal normal normal 24px/43px Informa Pro;
    }
}

@media (max-width: 1440px) {
    .title {
        font-size: 45px;
    }
    .description {
        font: normal normal normal 20px/43px Informa Pro;
    }
    .btn_quiz {
        font: normal normal bold 20px/40px Poppins;
    }
    .link_search {
        font-size: 20px;
    }
}

@media (max-width: 1367px) {
    
    .title {
        font-size: 40px;
    }
    .description {
        font: normal normal normal 20px/43px Informa Pro;
    }
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
    .intro_content {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding: 0px 20px 60px 20px;
        gap: 20px;
    }
    .content{
        justify-content: center;
        align-items: center;
    }
    .content:nth-child(1) {
        padding: 0px;
    }
    .title {
        margin: 0px;
        text-align: center;
        font-size: 40px;
    }
    .cont_search {
        margin-top: 20px;
    }
    .link_search {
        font-size: 20px;
    }
    .intro_img {
        min-height: 300px;
    }
    .btn_quiz {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .title {
        font-size: 35px;
    }
    .btn_quiz {
        margin-top: 30px;
        font: normal normal bold 18px/30px Poppins;
    }
    .link_search {
        font-size: 18px;
    }
}

@media (max-width: 585px) {
    .title {
        font-size: 26px;
    }
}

@media (max-width: 480px) {
    .intro_content{
        padding: 0px 10px 60px 10px;
    }
    .description {
        font: normal normal normal 20px/40px Informa Pro;
        padding: 0px 10px;
    }
}