@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/********************************** Contact Us **********************************/

.contact-us {
  width: 100%;
  min-height: 963px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  padding: 0px 40px;
}
.contact-us .row{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact-us .content {
  z-index: 1;
  position: relative;
  max-width: var(--content-width);
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-gap: 40px;
  gap: 40px;
  padding-bottom: 40px;
}
.contact-us .content .cont-left {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.contact-us .content .cont-left .title {
  margin-top: 134px;
  text-align: left;
  /* font: normal normal medium 65px/78px Filson Soft; */
  font-family: Filson Soft;
  font-size: 50px;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #ffffff;
}
.contact-us .content .cont-left .description {
  margin-top: 49px;
  max-width: 707px;
  text-align: left;
  font: normal normal normal 26px/43px Informa Pro;
  letter-spacing: 0px;
  color: #ffffff;
}
.contact-us .content .cont-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.contact-us .content .cont-right .form-contact {
  margin-top: 60px;
  max-width: 720px;
  width: 100%;
  min-height: 789px;
  background: #144460 0% 0% no-repeat padding-box;
  border-radius: 32px;
  overflow: hidden;
  padding: 44px 55px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-row-gap: 18px;
  row-gap: 18px;
}
.contact-us .content .cont-right .form-contact .cont-client-name {
  width: 100%;
  display: grid;
  grid-template-columns: 249px 341px;
  grid-gap: 18px;
  gap: 18px;
}
.contact-us .content .cont-right .form-contact .name-gap{
    display: flex;
    flex-direction: column;
    grid-gap: 18px;
    gap: 18px;
}
.contact-us .content .cont-right .form-contact .contact-input {
  width: 100%;
  height: 71px;
  background: #f1f2f2 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 22px 26px;
  font: normal normal normal 22px/27px Informa Pro;
  letter-spacing: 0px;
}
.contact-us .content .cont-right .form-contact .contact-input {
  outline: none;
  border: none;
  background-color: red;
  width: 100%;
  height: 71px;
  background: #f1f2f2 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 22px 26px;
  font: normal normal normal 22px/27px Informa Pro;
  letter-spacing: 0px;
}
.contact-us .content .cont-right .form-contact .contact-input.message {
  height: 341px;
}
.contact-us .content .cont-right .form-contact .contact-text-area {
  width: 100%;
  height: 167px;
  background: #f1f2f2 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 22px 26px;
  font: normal normal normal 22px/27px Informa Pro;
  letter-spacing: 0px;
}

.contact-us .content .cont-right .form-contact .contact-input:-ms-input-placeholder, .contact-us .content .cont-right .form-contact .contact-text-area:-ms-input-placeholder{
    text-align: left;
    font: normal normal normal 22px/27px Informa Pro;
    letter-spacing: 0px;
    color: #707070;
}

.contact-us .content .cont-right .form-contact .contact-input::placeholder,
.contact-us .content .cont-right .form-contact .contact-input:-ms-input-placeholder,
.contact-us .content .cont-right .form-contact .contact-input::-ms-input-placeholder, 
.contact-us .content .cont-right .form-contact .contact-text-area::placeholder,
.contact-us .content .cont-right .form-contact .contact-text-area:-ms-input-placeholder,
.contact-us .content .cont-right .form-contact .contact-text-area::-ms-input-placeholder{
    text-align: left;
    font: normal normal normal 22px/27px Informa Pro;
    letter-spacing: 0px;
    color: #707070;
}
.contact-us .content .cont-right .form-contact .contact-input.select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.contact-us .content .cont-right .form-contact .contact-input.placeholder{
  color: #707070;
}
.contact-us .content .cont-right .form-contact .contact-input.select option{
  width: 100%;
  height: 71px !important;
  color: black;
}
.contact-us .content .cont-right .form-contact select::-ms-expand {
  display: none;
}
.contact-us .content .cont-right .form-contact .btn-submit {
  width: 100%;
  height: 71px;
  background: #6bc17f 0% 0% no-repeat padding-box;
  border-radius: 12px;
  text-align: center;
  font: normal normal bold 30px/46px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .contact-us .content .cont-left .title {
        font-size: 50px;
    }
    .contact-us .content .cont-left .description {
        font: normal normal normal 22px/45px Informa Pro;
    }
}

@media (max-width: 1367px) {
    .contact-us .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .contact-us .content .cont-right .form-contact .cont-client-name {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
    .contact-us {
      padding: 0px 40px;
    }
    .contact-us .content{
      grid-gap: 40px;
      gap: 40px;
    }
    .contact-us .content .cont-right {
        justify-content: center;
        align-items: center;
    }
    .contact-us .content .cont-left .title {
        font-size: 40px;
    }
    .contact-us .content .cont-left .description {
        font: normal normal normal 20px/50px Informa Pro;
    }
    .contact-us .content {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .contact-us {
        padding: 0px 10px;
    }
    .contact-us .content .cont-right .form-contact .cont-client-name {
        display: flex;
        flex-direction: column;
    }
    .contact-us .content .cont-left .title,
    .contact-us .content .cont-left .description {
        padding: 0px 10px;
    }
    .contact-us .content .cont-right .form-contact .contact-input,
    .contact-us .content .cont-right .form-contact .contact-input.select
    {
      min-height: 60px;
      height: 60px;
      padding: 10px 46px 10px 26px;
      font: normal normal normal 16px/19px Informa Pro;
    }
    .contact-us .content .cont-right .form-contact {
      margin-top: 0px;
      padding: 40px 40px;
    }
    .contact-us .content .cont-right .form-contact .btn-submit {
      height: 50px;
      font-size: 20px;
    }
}

@media (max-width: 585px) {
    .contact-us .content .cont-right .form-contact {
        padding: 40px 30px;
    }
    
}

@media (max-width: 480px) {
    .contact-us .content .cont-left .title {
        font-size: 35px;
    }
    .contact-us .content .cont-left .description {
        font: normal normal normal 20px/50px Informa Pro;
    }
}

.CardCharity_charity_card__TAy0P {
    max-width: 1181px;
    width: 100%;
    min-height: 283px;
    display: grid;
    grid-template-columns: 283px 1fr 259px;
    border-radius: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 6px #00000029;
    overflow: hidden;
}

.CardCharity_card_img__1jf6L {
    background: #dedede;
}

.CardCharity_card_content__2-LE9 {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* row-gap: 10px; */
    padding: 10px 0px 10px 20px;
}

.CardCharity_card_title__3_swr {
    text-align: left;
    /* font: normal normal medium 35px/53px Poppins; */
    font-size: 35px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: var(--color-blue);
}

.CardCharity_location__25P9y {
    text-align: left;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: var(--color-gray);
}

.CardCharity_card_description__rrfeN {
    max-width: 651px;
    width: 100%;
    min-height: 155px;
    text-align: left;
    font: normal normal normal 25px/30px Informa Pro;
    letter-spacing: 0px;
    color: var(--color-blue);
    /* overflow: hidden; */
    padding: 15px 0px;
}

.CardCharity_card_actions__2_uOq {
    padding: 0px 45px 0px 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 38px;
    row-gap: 38px;
}

.CardCharity_btn_action__ZFGGD {
    max-width: 183px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background-color: var(--color-green);
    color: var(--color-blue);
    box-shadow: 3px 3px 6px #00000029;
    text-align: center;
    font: normal normal bold 25px/38px Poppins;
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CardCharity_btn_action__ZFGGD:hover {
    background-color: #fff;
    transition: 0.3s ease all;
}


/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .CardCharity_charity_card__TAy0P {
        max-width: 925px;
        min-height: 200px;
        display: grid;
        grid-template-columns: 200px 1fr 200px;
    }
    .CardCharity_card_title__3_swr {
        text-align: left;
        font-size: 25px;
    }
    .CardCharity_card_description__rrfeN {
        margin-top: 17px;
        max-width: 512px;
        width: 100%;
        min-height: 108px;
        font: normal normal normal 20px/22px Informa Pro;
    }
    .CardCharity_btn_action__ZFGGD {
        max-width: 165px;
        width: 100%;
        height: 40px;
        font: normal normal bold 20px/38px Poppins;
    }
}

@media (max-width: 1200px) {}

@media (max-width: 991px) {}

@media (max-width: 767px) {
    .CardCharity_charity_card__TAy0P {
        max-width: 1181px;
        width: 100%;
        min-height: 283px;
        grid-template-columns: 1fr 1fr;
    }
    .CardCharity_card_img__1jf6L {
        order: 1;
        background: #DEDEDE 0% 0% no-repeat padding-box;
        border-radius: 0px 26px 26px 0px;
        width: 100%;
        height: 141px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
    }
    .CardCharity_card_actions__2_uOq {
        order: 2;
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 1;
    }
    .CardCharity_card_content__2-LE9 {
        width: 100%;
        max-width: auto;
        order: 3;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
    }
    .CardCharity_card_title__3_swr {
        text-align: center;
    }
    .CardCharity_card_description__rrfeN {
        margin-top: 10px;
        font: normal normal normal 16px/22px Informa Pro;
    }
    .CardCharity_card_actions__2_uOq {
        padding: 20px;
        justify-content: center;
        align-items: center;
        grid-gap: 20px;
        gap: 20px;
    }
}

@media (max-width: 585px) {
    
    .CardCharity_btn_action__ZFGGD {
        width: 100%;
        font: normal normal bold 18px/38px Poppins;
    }
}

@media (max-width: 480px) {
    .CardCharity_card_content__2-LE9 {
        padding: 10px 20px;
    }
}
.Dropdown_item__1ArmD {
  display: flex;
  flex-direction: column;
}

.Dropdown_list_type_name__3x9Y-{
  text-align: left;
  font: normal normal medium 35px/53px Poppins;
  font-size: 35px;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #E6E7E8;
}

.Dropdown_charity_type_list__WKC6F {
  display: flex;
  flex-direction: column;
}

.Dropdown_dropdown_title__3gvyg{
  text-align: left;
  font: normal normal medium 25px/38px Poppins;
  font-size: 25px;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #E6E7E8;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.Dropdown_dropdown_item__-oAS9{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font: normal normal normal 25px/30px Informa Pro;
  letter-spacing: 0px;
  color: #E6E7E8;
  padding-left: 25px;
}

.Dropdown_check_item__1YK5s{
  cursor: pointer;
}

/********************** Media Queries **********************/

@media (max-width: 1440px) {
  .Dropdown_list_type_name__3x9Y-{
    font-size: 30px;
  }
  .Dropdown_dropdown_title__3gvyg{
    font-size: 22px;
  }
  .Dropdown_dropdown_item__-oAS9{
    font-size: 18px;
  }
}

@media (max-width: 1367px) {
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
}

@media (max-width: 585px) {
}

@media (max-width: 480px) {
}
.Sidebar_sidebar__2pkrv {
  position: relative;
  background: var(--color-blue);
  width: 100%;
  height: 100%;
  min-height: 600px;
  padding: 25px 10px 60px 52px;
  border-radius: 0px 0px 26px 0px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #fff;
}

.Sidebar_sidebar_content__3aSjP {
  margin-top: 73px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}

.Sidebar_sidebar_row__3u2Ci{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Sidebar_sidebar_search__1Irif {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 58px;
  max-width: 266px;
  width: 100%;
  padding: 15px 11px 17px 51px;
  background: #F1F2F2 0% 0% no-repeat padding-box;
  text-align: left;
  font: normal normal normal 22px/27px Informa Pro;
  letter-spacing: 0px;
}

.Sidebar_list_type_name__28Cdr {
  text-align: left;
  font: normal normal medium 35px/53px Poppins;
  font-size: 35px;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #E6E7E8;
}

.Sidebar_charity_type_list__3DT_8 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  grid-row-gap: 20px;
  row-gap: 20px;
}

/********************** Media Queries **********************/

@media (max-width: 1600px) {
  .Sidebar_sidebar__2pkrv {
    padding: 0px 20px 25px 30px;
  }
  .Sidebar_sidebar_content__3aSjP {
    margin-top: 50px;
  }
  .Sidebar_list_type_name__28Cdr {
    font-size: 30px;
  }
  .Sidebar_sidebar_search__1Irif {
    font-size: 20px;
  }
}


@media (max-width: 1440px) {
  .Sidebar_sidebar__2pkrv {
    padding: 0px 20px 25px 30px;
  }
  .Sidebar_sidebar_content__3aSjP {
    margin-top: 50px;
  }
  .Sidebar_list_type_name__28Cdr {
    font-size: 30px;
  }
  .Sidebar_sidebar_search__1Irif {
    font-size: 20px;
  }
}

@media (max-width: 1367px) {
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
  .Sidebar_sidebar__2pkrv {
    display: none;
  }
}

@media (max-width: 767px) {
}

@media (max-width: 585px) {
}

@media (max-width: 480px) {
}
/********************************** Find Your Charity **********************************/
.FindYourCharity_container__2iII0{
    background: var(--color-white-1);
}

.FindYourCharity_view__oLF1c {
    position: relative;
    display: grid;
    grid-template-columns: 413px 1fr;
}

.FindYourCharity_content__BESSq {
    position: relative;
    width: 100%;
    min-height: 717px;
}

.FindYourCharity_head_content__DajH7{
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
    margin: 49px 20px 0px 20px;
}

.FindYourCharity_content_center__tX0eR{
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.FindYourCharity_title__2hj9t {
    text-align: left;
    font: normal normal medium 65px/78px Filson Soft;
    font-family: Filson Soft;
    font-size: 50px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #FFFFFF;
}

.FindYourCharity_description__v_zUA {
    position: relative;
    z-index: 2;
    text-align: left;
    font: normal normal normal 35px/43px Informa Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.FindYourCharity_btn_mobile_filter__1oCTp{
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    font: normal normal bold 19px/29px Poppins;
    font-size: 18px;
    letter-spacing: 0px;
    color: #144460;
    background: #6BC17F 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 10px;
    max-width: 382px;
    width: 100%;
    min-height: 60px;
    grid-gap: 10px;
    gap: 10px;
    z-index: 2;
}

.FindYourCharity_bg_overlay__2EDH6 {
    position: absolute;
    width: 100%;
    height: 717px;
    z-index: 1;
    background-size: cover;
}

.FindYourCharity_results__3L7Vx {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.FindYourCharity_charity_list__L7mIs {
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 25px;
    row-gap: 25px;
    grid-column-gap: 25px;
    -webkit-column-gap: 25px;
            column-gap: 25px;
    padding: 20px 20px;
}


/********************** Media Queries **********************/

@media (max-width: 1600px) {
    .FindYourCharity_view__oLF1c {
        grid-template-columns: 350px 1fr;
    }
    .FindYourCharity_head_content__DajH7{ 
        margin: 40px 20px 0px 20px;
    }
    .FindYourCharity_title__2hj9t {
        font-size: 55px;
    }

    .FindYourCharity_description__v_zUA {
        font-size: 30px;
    }
}

@media (max-width: 1440px) {
    .FindYourCharity_view__oLF1c {
        grid-template-columns: 300px 1fr;
    }
    .FindYourCharity_title__2hj9t {
        font-size: 55px;
    }
    .FindYourCharity_description__v_zUA {
        font-size: 28px;
    }
}

@media (max-width: 1367px) {
    .FindYourCharity_title__2hj9t {
        font-size: 50px;
    }
    .FindYourCharity_description__v_zUA {
        font-size: 25px;
    }
}

@media (max-width: 1200px) {
    .FindYourCharity_title__2hj9t {
        font-size: 45px;
    }
}

@media (max-width: 991px) {
    .FindYourCharity_view__oLF1c {
        display: flex;
        flex-direction: column;
    }
    .FindYourCharity_head_content__DajH7{ 
        margin: 40px 20px 40px 20px;
    }
    .FindYourCharity_title__2hj9t {
        font-size: 40px;
    }
    .FindYourCharity_btn_mobile_filter__1oCTp{
        display: flex;
    }
}

@media (max-width: 767px) {
    .FindYourCharity_title__2hj9t {
        font-size: 35px;
    }
}

@media (max-width: 585px) {
    .FindYourCharity_title__2hj9t {
        font-size: 30px;
    }
}

@media (max-width: 480px) {}
.Mobile_modal__9Pwdn{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background: rgba(0,0,0, 0.75);
    display: none;
    justify-content: center;
    align-items: center;
    padding: 19px;
}
.Mobile_modal__9Pwdn.Mobile_show__crKwJ{
    display: flex;
}
.Mobile_modal_container__3DI09{
    padding: 1rem;
    position: relative;
    width: 100%;
    min-height: 366px;
    background: #144460 0% 0% no-repeat padding-box;
    border-radius: 36px;
    max-width: 451px;
    max-height: 100vh;
    overflow-y: auto;
    
}

@media (min-width: 991px){
    .Mobile_modal__9Pwdn,
    .Mobile_modal__9Pwdn.Mobile_show__crKwJ{
        display: none;
    }
}

@media (max-width: 480px){
    .Mobile_modal__9Pwdn{
        padding: 0px;
    }
}

.MobileFilters_content___QUVp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}

.MobileFilters_filter_title__Pacln{
    text-align: left;
    font: normal normal bold 35px/53px Poppins;
    font-size: 35px;
    letter-spacing: 0px;
    color: #F1F2F2;
}

.MobileFilters_filter_question__2Aasm{
    margin-top: 20px;
    text-align: center;
    font: normal normal medium 20px/30px Poppins;
    font-size: 20px;
    letter-spacing: 0px;
    color: #F1F2F2;
}

.MobileFilters_cont_actions__3s8-J{
    margin-top: 20px;
    width: 100%;
    min-height: 143px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 13px;
    gap: 13px;
}

.MobileFilters_btn_filter__3CUCg{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 319px;
    width: 100%;
    height: 46px;
    background: #F1F2F2 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 10px;
    text-align: center;
    font: normal normal bold 17px/26px Poppins;
    letter-spacing: 0px;
}
.MobileFilters_btn_filter__3CUCg.MobileFilters_active__ulUVy{
    background: #6BC17F 0% 0% no-repeat padding-box;
}

.MobileFilters_btn_apply__pqqph{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 319px;
    width: 100%;
    height: 46px;
    background: #6BC17F 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 10px;
    text-align: center;
    font: normal normal bold 19px/29px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    cursor: pointer;
}
.MobileFilters_btn_apply__pqqph:hover{
    color: #144460;
}

.MobileFilters_btn_reset__qBVgF{
    margin-top: 13px;
    text-align: left;
    font: normal normal bold 15px/23px Poppins;
    letter-spacing: 0px;
    color: #F1F2F2;
    cursor: pointer;
}

.MobileFilters_multi_select__3ZBQQ{
    max-width: 319px;
    width: 100%;
    /* min-height: 46px; */
    border-radius: 10px !important;
}

@media (max-width: 480px){
    .MobileFilters_filter_question__2Aasm{
        font-size: 18px;
    }
}


.Pagination_pagination__1KkdA {
    min-height: 40px;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
}

.Pagination_link__G-0N5 {
    text-align: left;
    font: normal normal normal 30px/37px Filson Soft;
    letter-spacing: 0px;
    color: #21445E;
    cursor: pointer;
}

.Pagination_link__G-0N5.Pagination_active__3SYsN {
    text-align: left;
    font: normal normal normal 30px/37px Filson Soft;
    letter-spacing: 0px;
    color: #6BC17F;
}

@media (max-width: 1600px) {
    .Pagination_link__G-0N5,
    .Pagination_link__G-0N5.Pagination_active__3SYsN {
        font-size: 30px;
    }
}

@media (max-width: 1440px) {
    .Pagination_link__G-0N5,
    .Pagination_link__G-0N5.Pagination_active__3SYsN  {
        font-size: 25px;
    }
}

@media (max-width: 1367px) {
    .Pagination_link__G-0N5,
    .Pagination_link__G-0N5.Pagination_active__3SYsN  {
        font-size: 20px;
    }
}

@media (max-width: 991px) {
    .Pagination_link__G-0N5,
    .Pagination_link__G-0N5.Pagination_active__3SYsN  {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .Pagination_title__Fj6Hg {
        font-size: 35px;
    }
}

@media (max-width: 585px) {
    .Pagination_title__Fj6Hg {
        font-size: 30px;
    }
}
.PaginationRow_row__GuapU {
    width: 100%;
    min-height: 141px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 413px;
}
.PaginationRow_content__1tzG0{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
}

@media (max-width: 1600px) {
    .PaginationRow_row__GuapU {
        padding-left: 350px;
    }
}

@media (max-width: 1440px) {
    .PaginationRow_row__GuapU {
        padding-left: 300px;
    }
}

@media (max-width: 991px) {
    .PaginationRow_row__GuapU {
        display: flex;
        flex-direction: column;
        padding-left: 0px;
    }
}
.Card_card__2QMqU {
    max-width: 398px;
    width: 100%;
    height: 511px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 6px #00000029;
    border-radius: 26px;
    overflow: hidden;
}

.Card_card_img__1Pg9k {
    height: 281px;
    width: 100%;
    cursor: pointer;
}

.Card_card_body__1R51b {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 10px 10px;
}

.Card_card_title__1MXie {
    max-width: 307px;
    height: 110px;
    text-align: center;
    /* font: normal normal medium 35px/53px Poppins; */
    font-size: 24px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #144460;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Card_card_actions__2SVJJ {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Card_btn_learn_more__ydp9g {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 226px;
    width: 100%;
    min-height: 60px;
    background: #6bc17f 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 15px;
    text-align: center;
    font: normal normal bold 22px/38px Poppins;
    letter-spacing: 0px;
    color: #144460;
}

.Card_btn_learn_more__ydp9g:hover {
    background-color: #fff;
}

/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .Card_card_title__1MXie {
        font-size: 24px;        
    }
}

@media (max-width: 1367px) {
    .Card_card__2QMqU {
        height: 400px;
        max-width: 300px;
        width: 100%;
    }
    .Card_card_img__1Pg9k {
        height: 200px;
        width: 100%;
    }
    .Card_card_title__1MXie {
        font-size: 22px;
        height: 90px;
    }
    .Card_card_body__1R51b {
        padding: 10px 20px 10px 20px;
    }
    .Card_btn_learn_more__ydp9g {
        font-size: 20px;
    }
}

@media (max-width: 1200px) {
    .Card_card_title__1MXie {
        font-size: 22px;
        height: 80px;
    }
}

@media (max-width: 767px) {
    .Card_card__2QMqU {
        height: auto;
        min-height: auto;
    }
    .Card_card_img__1Pg9k{
        height: 123px;
    }
    .Card_card_content__uIlz4 {
        min-height: 110px;
    }
    .Card_card_title__1MXie{
        font: normal normal medium 20px/30px Poppins;
        font-size: 18px;
    }
    .Card_btn_learn_more__ydp9g {
        display: none;
    }
}

@media (max-width: 585px) {
    
}

.CardList_sec_change_world__2UeLP {
    width: 100%;
    /* min-height: 1498px; */
    min-height: 800px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    background: #f1f2f2 0% 0% no-repeat padding-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
}

.CardList_content__3bSxK {
    max-width: var(--content-width);
    width: 100%;
}

.CardList_title__3ZOtS {
    text-align: center;
    /* font: normal normal medium 65px/98px Poppins; */
    font-size: 45px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #144460;
}

.CardList_cont_cards__20G56 {
    margin-top: 42px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-gap: 40px;
    gap: 40px;
}

/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .CardList_title__3ZOtS {
        font-size: 45px;
    }
    .CardList_cont_cards__20G56 {
        grid-gap: 40px;
        gap: 40px;
    }
}

@media (max-width: 1367px) {
    .CardList_title__3ZOtS {
        font-size: 40px;
    }
    .CardList_cont_cards__20G56 {
        grid-gap: 50px;
        gap: 50px;
    }
    .CardList_sec_change_world__2UeLP {
        /* min-height: 1200px; */
        min-height: 800px;
    }
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
    .CardList_title__3ZOtS {
        font-size: 35px;
    }
}

@media (max-width: 767px) {
    .CardList_title__3ZOtS {
        font-size: 35px;
    }
    .CardList_cont_cards__20G56 {
        grid-gap: 30px;
        gap: 30px;
    }
}

@media (max-width: 585px) {
    .CardList_title__3ZOtS {
        font-size: 26px;
    }
}

@media (max-width: 480px) {
    .CardList_sec_change_world__2UeLP {
        padding: 60px 10px;
    }
    
}
.PRLogo_cont_pr_logos__72nrs {
    width: 100%;
    min-height: 107px;
    background: #144460 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    display: flex;
    justify-content: center;
    padding: 40px 20px;
}

.PRLogo_content__1W14l {
    max-width: var(--content-width);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    grid-gap: 30px;
    gap: 30px;
}

.PRLogo_text_pr_logo__2CVaY {
    max-width: 120px;
    width: 100%;
    text-align: center;
    /* font: normal normal medium 20px/30px Poppins; */
    font-size: 20px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #e6e7e8;
}


/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .PRLogo_content__1W14l {
        max-width: 1367px;
    }
    .PRLogo_text_pr_logo__2CVaY {
        font-size: 18px;
    }
}

@media (max-width: 1367px) {
    .PRLogo_content__1W14l {
        max-width: 1200px;
    }
}

@media (max-width: 585px) {
    .PRLogo_content__1W14l {
        align-items: center;
        justify-content: center;
    }
    .PRLogo_text_pr_logo__2CVaY {
        width: auto;
    }
}

@media (max-width: 480px) {}
.Intro_intro__2mV0Z{
    width: 100%;
    background: #E6E7E8 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Intro_intro_content__1Vc37 {
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 135px;
    padding: 10px 20px 60px 20px;
    grid-gap: 40px;
    gap: 40px;
}

.Intro_content__1Ty_K {
    width: 100%;
    min-height: 375px;
}

.Intro_content__1Ty_K:nth-child(1) {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Intro_cont_left__3a01Z {
    display: flex;
    flex-direction: column;
}

.Intro_title__3EvR- {
    text-align: left;
    font-size: 50px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #144460;
}

.Intro_description__3wsZy {
    margin-top: 41px;
    text-align: left;
    font: normal normal normal 26px/43px Informa Pro;
    letter-spacing: 0px;
    color: #21445e;
}

.Intro_btn_quiz__3E7yX {
    margin-top: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 414px;
    width: 100%;
    height: 69px;
    background: #6bc17f 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 15px;
    text-align: center;
    font: normal normal bold 26px/40px Poppins;
    letter-spacing: 0px;
    color: #144460;
    transition: all 0.3s ease;
}

.Intro_btn_quiz__3E7yX:hover {
    background-color: #fff;
}

.Intro_intro_img__TQskW {
    max-width: 748px;
    width: 100%;
    min-height: 375px;
}

.Intro_cont_search__2St5d {
    margin-top: 14.25px;
    max-width: 414px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Intro_link_search__1v4cv {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    text-align: left;
    font: normal normal medium 26px/38px Poppins;
    font-size: 24px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #6bc17f;
}

/********************** Media Queries **********************/

@media (max-width: 1640px) {
    .Intro_title__3EvR- {
        font-size: 50px;
    }
    .Intro_content__1Ty_K {
        padding: 0px 20px;
    }
    .Intro_description__3wsZy {
        font: normal normal normal 24px/43px Informa Pro;
    }
}

@media (max-width: 1440px) {
    .Intro_title__3EvR- {
        font-size: 45px;
    }
    .Intro_description__3wsZy {
        font: normal normal normal 20px/43px Informa Pro;
    }
    .Intro_btn_quiz__3E7yX {
        font: normal normal bold 20px/40px Poppins;
    }
    .Intro_link_search__1v4cv {
        font-size: 20px;
    }
}

@media (max-width: 1367px) {
    
    .Intro_title__3EvR- {
        font-size: 40px;
    }
    .Intro_description__3wsZy {
        font: normal normal normal 20px/43px Informa Pro;
    }
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
    .Intro_intro_content__1Vc37 {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding: 0px 20px 60px 20px;
        grid-gap: 20px;
        gap: 20px;
    }
    .Intro_content__1Ty_K{
        justify-content: center;
        align-items: center;
    }
    .Intro_content__1Ty_K:nth-child(1) {
        padding: 0px;
    }
    .Intro_title__3EvR- {
        margin: 0px;
        text-align: center;
        font-size: 40px;
    }
    .Intro_cont_search__2St5d {
        margin-top: 20px;
    }
    .Intro_link_search__1v4cv {
        font-size: 20px;
    }
    .Intro_intro_img__TQskW {
        min-height: 300px;
    }
    .Intro_btn_quiz__3E7yX {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .Intro_title__3EvR- {
        font-size: 35px;
    }
    .Intro_btn_quiz__3E7yX {
        margin-top: 30px;
        font: normal normal bold 18px/30px Poppins;
    }
    .Intro_link_search__1v4cv {
        font-size: 18px;
    }
}

@media (max-width: 585px) {
    .Intro_title__3EvR- {
        font-size: 26px;
    }
}

@media (max-width: 480px) {
    .Intro_intro_content__1Vc37{
        padding: 0px 10px 60px 10px;
    }
    .Intro_description__3wsZy {
        font: normal normal normal 20px/40px Informa Pro;
        padding: 0px 10px;
    }
}
.Testimonials_sec_testimonials__Aqgu5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 600px;
    background: #e6e7e8 0% 0% no-repeat padding-box;
    padding: 40px 20px;
}

.Testimonials_title__2WwOY {
    text-align: center;
    /* font: normal normal medium 65px/98px Poppins; */
    font-size: 50px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #144460;
}

.Testimonials_cont_testimonials__1SBac {
    margin-top: 42px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-row-gap: 30px;
    row-gap: 30px;
}

/********************** Media Queries **********************/

@media (max-width: 1640px) {
    .Testimonials_title__2WwOY {
        font-size: 50px;
    }
}

@media (max-width: 1440px) {
    .Testimonials_title__2WwOY {
        font-size: 45px;
    }
}

@media (max-width: 1367px) {
    .Testimonials_title__2WwOY {
        font-size: 40px;
    }
}

@media (max-width: 1200px) {}

@media (max-width: 991px) {
    .Testimonials_title__2WwOY {
        text-align: center;
        font-size: 40px;
    }
}

@media (max-width: 767px) {
    .Testimonials_title__2WwOY {
        font-size: 35px;
    }
    .Testimonials_cont_testimonials__1SBac {
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

@media (max-width: 585px) {
    .Testimonials_title__2WwOY {
        font-size: 30px;
    }
}

@media (max-width: 480px) {
    
}
.Testimonial_testimonial__1agKc {
    display: grid;
    grid-template-columns: 57px 276px;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
}

.Testimonial_content__2ZW3a {
    display: flex;
    flex-direction: column;
    grid-row-gap: 19px;
    row-gap: 19px;
}

.Testimonial_name__1Npa_ {
    text-align: left;
    /* font: normal normal normal 30px/46px Poppins; */
    font-size: 30px;
    font-weight: var(--unnamed-font-weight-normal);
    letter-spacing: 0px;
    color: #21445e;
}

.Testimonial_description__36lDk {
    text-align: left;
    /* font: normal normal medium 20px/30px Poppins; */
    font-size: 20px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #707070;
}

/********************** Media Queries **********************/

@media (max-width: 1640px) {
    
}

@media (max-width: 1440px) {
    
}

@media (max-width: 1367px) {
    .Testimonial_name__1Npa_ {
        font-size: 25px;
    }
    .Testimonial_description__36lDk {
        font-size: 18px;
    }
}

@media (max-width: 1200px) {}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
    
}

@media (max-width: 585px) {
    .Testimonial_name__1Npa_ {
        font-size: 22px;
    }
    .Testimonial_description__36lDk {
        font-size: 17px;
    }
}

@media (max-width: 480px) {
    .Testimonial_testimonial__1agKc {
        grid-template-columns: 57px 1fr;
    }
    .Testimonial_name__1Npa_ {
        font-size: 20px;
    }
    .Testimonial_description__36lDk {
        font-size: 16px;
    }
}
.Results_results__3nLtl{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Results_charity_list__1HEIw {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 40px;
    gap: 40px;
}

.Results_question__1rQcn {
    color: var(--color-blue);
    text-align: center;
    font: normal normal medium 65px/98px Poppins;
    font-weight: var(--unnamed-font-weight-medium);
    font-size: 50px;
    letter-spacing: 0px;
}

.Results_btn_retake__1RunH {
    margin-top: 50px;
    cursor: pointer;
    text-align: center;
    color: var(--color-green);
    font-size: 18px;
    font-weight: 700;
    border-radius: 10px;

    text-align: center;
    font: normal normal medium 25px/38px Poppins;
    font-size: 25px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
}


/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .Results_charity_list__1HEIw {
        margin: 60px 0px;
        grid-gap: 25px;
        gap: 25px;
    }
    .Results_btn_retake__1RunH {
        margin-top: 0px;
        font: normal normal medium 22px/35px Poppins;
        font-size: 22px;
    }
}

@media (max-width: 1367px) {}

@media (max-width: 1200px) {}

@media (max-width: 991px) {
    .Results_charity_list__1HEIw {
        margin: 60px 0px;
    }
}

@media (max-width: 767px) {
    .Results_charity_list__1HEIw {
        margin: 40px 0px;
    }
}

@media (max-width: 585px) {
    .Results_btn_retake__1RunH {
        font: normal normal medium 22px/35px Poppins;
        font-size: 18px;
    }
}

@media (max-width: 480px) {}
.CardAnswer_card__3lnrX {
    max-width: 398px;
    width: 100%;
    min-height: 511px;
    background: #F1F2F2 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 6px #00000029;
    border-radius: 26px;
    overflow: hidden;
}
.CardAnswer_card__3lnrX:hover,
.CardAnswer_card__3lnrX.CardAnswer_active__22v9V {
    background-color: var(--color-green);
    transition: 0.3s ease all;
    cursor: pointer;
}
.CardAnswer_card_img__1pxvB {
    height: 332px;
    width: 100%;
    background-color: gray;
    color: var(--color-blue);
}
.CardAnswer_card_content__2SIX8 {
    min-height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.CardAnswer_card_title__14KCg {
    color: var(--color-blue);
    text-align: center;
    font: normal normal medium 35px/53px Poppins;
    font-size: 35px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
}
.CardAnswer_card_actions__mSNMH {
    display: flex;
    justify-content: center;
    align-items: center;
}


/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .CardAnswer_card_list__b5xpd {
        grid-column-gap: 40px;
        -webkit-column-gap: 40px;
                column-gap: 40px;
        grid-row-gap: 30px;
        row-gap: 30px;
    }
}

@media (max-width: 1367px) {
    .CardAnswer_card_title__14KCg {
        font-size: 30px;        
    }
    .CardAnswer_card_list__b5xpd {
        margin-top: 60px;
        grid-gap: 30px;
        gap: 30px;
    }
    .CardAnswer_card__3lnrX {
        min-height: 400px;
        max-width: 300px;
        width: 100%;
    }
    .CardAnswer_card_img__1pxvB {
        height: 250px;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .CardAnswer_card__3lnrX {
        min-height: auto;
    }
    .CardAnswer_card_img__1pxvB{
        height: 123px;
    }
    .CardAnswer_card_content__2SIX8 {
        min-height: 110px;
    }
    .CardAnswer_card_title__14KCg{
        font: normal normal medium 20px/30px Poppins;
        font-size: 20px;
    }
}


@media (max-width: 585px) {
    .CardAnswer_card_title__14KCg {
        font-size: 28px;        
    }
}

.Animals_card_list__2Ue-I {
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-column-gap: 41px;
    -webkit-column-gap: 41px;
            column-gap: 41px;
    grid-row-gap: 40px;
    row-gap: 40px;
}

.Animals_card__1y8Hm {
    max-width: 300px;
    width: 100%;
    background: #F1F2F2 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 6px #00000029;
    border-radius: 26px;
    overflow: hidden;
    cursor: pointer;
}

/* .card:hover, */
.Animals_card__1y8Hm.Animals_active__1XBlC {
    background-color: var(--color-green);
    transition: 0.3s ease all;
}

.Animals_card_img__3gDlU {
    width: 100%;
    height: 286px;
    background-color: gray;
    color: var(--color-blue);
}

.Animals_card_content__3cq0I {
    min-height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Animals_card_title__3sDwJ {
    color: var(--color-blue);
    text-align: center;
    font: normal normal medium 35px/53px Poppins;
    font-size: 35px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
}

.Animals_card_actions__16Ec3 {
    display: flex;
    justify-content: center;
    align-items: center;
}

/********************** Media Queries **********************/

@media (max-width: 1800px) {
    .Animals_card__1y8Hm {
        height: 400px;
        width: 100%;
    }
    .Animals_card_list__2Ue-I {
        margin-top: 60px;
        grid-column-gap: 60px;
        -webkit-column-gap: 60px;
                column-gap: 60px;
        grid-row-gap: 30px;
        row-gap: 30px;
    }
    .Animals_card_img__3gDlU {
        height: 250px;
        width: 100%;
    }
    .Animals_card_title__3sDwJ {
        font-size: 30px;
    }
}

@media (max-width: 1440px) {
    .Animals_card_list__2Ue-I {
        margin-top: 60px;
        grid-column-gap: 60px;
        -webkit-column-gap: 60px;
                column-gap: 60px;
        grid-row-gap: 40px;
        row-gap: 40px;
    }
}

@media (max-width: 991px) {
    .Animals_card__1y8Hm {
        height: auto;
        min-height: auto;
    }
    .Animals_card_content__3cq0I {
        min-height: auto;
    }
    .Animals_card_img__3gDlU{
        height: 123px;
    }
    .Animals_card_content__3cq0I {
        height: 110px;
    }
    .Animals_card_title__3sDwJ{
        font: normal normal medium 20px/30px Poppins;
        font-size: 20px;
    }
}


@media (max-width: 585px) {
    .Animals_card_list__2Ue-I {
        margin-top: 60px;
        grid-column-gap: 50px;
        -webkit-column-gap: 50px;
                column-gap: 50px;
        grid-row-gap: 30px;
        row-gap: 30px;
    }
    .Animals_card-title__2mbvR {
        font-size: 20px;
    }
}
.Area_card_list__344Kx {
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-column-gap: 52px;
    -webkit-column-gap: 52px;
            column-gap: 52px;
    grid-row-gap: 40px;
    row-gap: 40px;
}

.Area_card__1ohL6 {
    max-width: 398px;
    width: 100%;
    height: 454px;
    background: #F1F2F2 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 6px #00000029;
    border-radius: 26px;
    overflow: hidden;
    cursor: pointer;
}

/* .card:hover, */
.Area_card__1ohL6.Area_active__3EUck {
    background-color: var(--color-green);
    transition: 0.3s ease all;
}

.Area_card_img__Mpomn {
    width: 100%;
    min-height: 286px;
    background-color: gray;
    color: var(--color-blue);
}

.Area_card_content__2Ab36 {
    min-height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Area_card_title__2Ja7G {
    color: var(--color-blue);
    text-align: center;
    font: normal normal medium 35px/53px Poppins;
    font-size: 35px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
}

.Area_card_actions__1hWeo {
    display: flex;
    justify-content: center;
    align-items: center;
}

/********************** Media Queries **********************/

@media (max-width: 1800px) {
    .Area_card_list__344Kx {
        margin-top: 60px;
        grid-column-gap: 50px;
        -webkit-column-gap: 50px;
                column-gap: 50px;
        grid-row-gap: 30px;
        row-gap: 30px;
    }

    .Area_card_title__2Ja7G {
        font-size: 30px;
    }

    .Area_card__1ohL6 {
        min-height: 400px;
        max-width: 300px;
        width: 100%;
    }

    .Area_card_img__Mpomn {
        height: 250px;
        width: 100%;
    }
}

@media (max-width: 1440px) {
    .Area_card_list__344Kx {
        margin-top: 60px;
        grid-column-gap: 30px;
        -webkit-column-gap: 30px;
                column-gap: 30px;
        grid-row-gap: 30px;
        row-gap: 30px;
    }
}

@media (max-width: 991px) {
    .Area_card__1ohL6 {
        height: auto;
        min-height: auto;
    }
    .Area_card_content__2Ab36 {
        min-height: auto;
    }
    .Area_card_img__Mpomn{
        height: 123px;
        min-height: auto;
    }
    .Area_card_content__2Ab36 {
        height: auto;
        min-height: 110px;
    }
    .Area_card_title__2Ja7G{
        font: normal normal medium 20px/30px Poppins;
        font-size: 20px;
    }
}


@media (max-width: 585px) {
    .Area_card-title__SiDIq {
        font-size: 20px;
    }
}
.Age_card_list__36cn_ {
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-column-gap: 52px;
    -webkit-column-gap: 52px;
            column-gap: 52px;
    grid-row-gap: 40px;
    row-gap: 40px;
}

.Age_card__3B74e {
    max-width: 398px;
    width: 100%;
    min-height: 511px;
    background: #F1F2F2 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 6px #00000029;
    border-radius: 26px;
    overflow: hidden;
    cursor: pointer;
}

/* .card:hover, */
.Age_card__3B74e.Age_active__1BoFj {
    background-color: var(--color-green);
    transition: 0.3s ease all;
}

.Age_card_img__26VB3 {
    height: 332px;
    width: 100%;
    background-color: gray;
    color: var(--color-blue);
}

.Age_card_content__1D-a8 {
    min-height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Age_card_title__2HIvd {
    color: var(--color-blue);
    text-align: center;
    font: normal normal medium 35px/53px Poppins;
    font-size: 35px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
}

.Age_card_actions__D5imP {
    display: flex;
    justify-content: center;
    align-items: center;
}

/********************** Media Queries **********************/

@media (max-width: 1800px) {
    .Age_card_list__36cn_ {
        margin-top: 60px;
        grid-column-gap: 50px;
        -webkit-column-gap: 50px;
                column-gap: 50px;
        grid-row-gap: 30px;
        row-gap: 30px;
    }

    .Age_card_title__2HIvd {
        font-size: 30px;
    }

    .Age_card__3B74e {
        min-height: 400px;
        max-width: 300px;
        width: 100%;
    }

    .Age_card_img__26VB3 {
        height: 250px;
        width: 100%;
    }
}

@media (max-width: 1440px) {
    .Age_card_list__36cn_ {
        margin-top: 60px;
        grid-column-gap: 30px;
        -webkit-column-gap: 30px;
                column-gap: 30px;
        grid-row-gap: 30px;
        row-gap: 30px;
    }
}

@media (max-width: 991px) {
    .Age_card__3B74e {
        min-height: auto;
    }
    .Age_card_content__1D-a8 {
        min-height: auto;
    }
    .Age_card_img__26VB3{
        height: 123px;
    }
    .Age_card_content__1D-a8 {
        min-height: 110px;
    }
    .Age_card_title__2HIvd{
        font: normal normal medium 20px/30px Poppins;
        font-size: 20px;
    }
}

@media (max-width: 585px) {
    
}
/********************************** Map **********************************/
.map-img {
    width: 100%;
    height: 500px;
    transition: all 0.3s ease;
}

.map-selected {
    fill: var(--color-green);
}

.map-unselected {
    fill: var(--color-blue);
}

.map-selected:hover,
.map-unselected:hover {
    cursor: pointer;
}

@media (max-width: 991px) {
    .map-img {
        height: 400px;
    }
}

@media (max-width: 767px) {
    .map-img {
        height: auto;
    }
}
.QuizType_card_list__Qx8lS {
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-column-gap: 85px;
    -webkit-column-gap: 85px;
            column-gap: 85px;
    grid-row-gap: 40px;
    row-gap: 40px;
}
.QuizType_card__3NIi5 {
    max-width: 398px;
    width: 100%;
    min-height: 511px;
    background: #F1F2F2 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 6px #00000029;
    border-radius: 26px;
    overflow: hidden;
}
.QuizType_card__3NIi5:hover,
.QuizType_card__3NIi5.QuizType_active__17Nwz {
    background-color: var(--color-green);
    transition: 0.3s ease all;
    cursor: pointer;
}
.QuizType_card_img__JKidi {
    height: 332px;
    width: 100%;
    background-color: gray;
    color: var(--color-blue);
}
.QuizType_card_content__1wwbg {
    min-height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.QuizType_card_title__3HcST {
    color: var(--color-blue);
    text-align: center;
    font: normal normal medium 35px/53px Poppins;
    font-size: 35px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
}
.QuizType_card_actions__2-d87 {
    display: flex;
    justify-content: center;
    align-items: center;
}


/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .QuizType_card_list__Qx8lS {
        grid-column-gap: 40px;
        -webkit-column-gap: 40px;
                column-gap: 40px;
        grid-row-gap: 30px;
        row-gap: 30px;
    }
}

@media (max-width: 1367px) {
    .QuizType_card_title__3HcST {
        font-size: 30px;        
    }
    .QuizType_card_list__Qx8lS {
        margin-top: 60px;
        grid-gap: 30px;
        gap: 30px;
    }
    .QuizType_card__3NIi5 {
        min-height: 400px;
        max-width: 300px;
        width: 100%;
    }
    .QuizType_card_img__JKidi {
        height: 250px;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .QuizType_card__3NIi5 {
        min-height: auto;
    }
    .QuizType_card_img__JKidi{
        height: 123px;
    }
    .QuizType_card_content__1wwbg {
        min-height: 110px;
    }
    .QuizType_card_title__3HcST{
        font: normal normal medium 20px/30px Poppins;
        font-size: 20px;
    }
}


@media (max-width: 585px) {
    .QuizType_card_title__3HcST {
        font-size: 20px;        
    }
}

/********************************** Questinnaire **********************************/
.quiz {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 20px;
    background: #F1F2F2 0% 0% no-repeat padding-box;
}

.quiz .content{
    max-width: var(--content-width);
    width: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.quiz .question {
    color: var(--color-blue);
    text-align: center;
    font: normal normal medium 65px/98px Poppins;
    font-weight: var(--unnamed-font-weight-medium);
    font-size: 50px;
    letter-spacing: 0px;
}

.text_apply{
    color: var(--color-blue);
    text-align: center;
    font: normal normal medium 65px/98px Poppins;
    font-weight: var(--unnamed-font-weight-medium);
    margin-top: 30px;
    font-size: 45px;
}

.quiz .btn-quiz {
    width: 226px;
    height: 60px;

    font-weight: 700;
    font-size: 18px;
    background: #6BC17F 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 15px;
    
    text-align: center;
    font: normal normal bold 30px/46px Poppins;
    letter-spacing: 0px;
    color: #144460;
}

.quiz .cont_actions{
    margin-top: 66px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
}

.quiz .btn-quiz:hover {
    background: #fff;
}

.quiz .multiple-options {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-gap: 52px;
    gap: 52px;
}

.quiz .multiple-options .option {
    max-width: 307px;
    width: 100%;
    min-height: 98px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 24px;

    text-align: center;
    font: normal normal medium 30px/46px Poppins;
    font-size: 30px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #144460;
    cursor: pointer;
}

/* .quiz .multiple-options .option:hover, */
.quiz .multiple-options .option.active {
    background-color: var(--color-green);
    transition: 0.3s ease all;
}


/********************** Media Queries **********************/

@media (max-width: 1800px) {
    .quiz .question {
        font-size: 50px;
    }
    .text_apply{
        font-size: 30px;
    }
}
@media (max-width: 1440px) {
    .text_apply{
        font-size: 28px;
    }
    .quiz .card-list {
        grid-column-gap: 40px;
        -webkit-column-gap: 40px;
                column-gap: 40px;
        grid-row-gap: 30px;
        row-gap: 30px;
    }
    .quiz .multiple-options {
        grid-gap: 40px;
        gap: 40px;
    }
    .quiz .multiple-options .option {
        min-height: 80px;
        font-size: 22px;
    }
}

@media (max-width: 1367px) {
    .quiz .question {
        font-size: 40px;
    }
    .text_apply{
        font-size: 26px;
    }
    .quiz .card-list .card .card-content .card-title {
        font-size: 30px;        
    }
    .quiz .card-list {
        margin-top: 60px;
        grid-gap: 30px;
        gap: 30px;
    }
    .quiz .card-list .card {
        min-height: 400px;
        max-width: 300px;
        width: 100%;
    }
    .quiz .card-list .card .card-img {
        height: 250px;
        width: 100%;
    }
    .quiz .btn-quiz,
    .quiz .multiple-options .option {
        font: normal normal medium 30px/46px Poppins;
        font-size: 22px;
    }
    .quiz .multiple-options {
        grid-gap: 30px;
        gap: 30px;
    }
}

@media (max-width: 1200px) {
    .quiz .question {
        font-size: 35px;
    }
    .text_apply{
        font-size: 25px;
    }
}

@media (max-width: 991px) {
    
}

@media (max-width: 767px) {
    .quiz .question {
        font-size: 35px;
    }
    .text_apply{
        margin-top: 30px;
        font-size: 22px;
    }
    .quiz .multiple-options {
        grid-gap: 20px;
        gap: 20px;
    }
    .quiz .multiple-options .option{
        height: 46px;
        min-height: auto;
        font: normal normal bold 17px/26px Poppins;
        font-size: 17px;
    }
    .quiz .cont_actions{
        flex-direction: column-reverse;
        grid-gap: 40px;
        gap: 40px;
    }
}

@media (max-width: 585px) {
    .quiz .question {
        font-size: 30px;
    }
    .text_apply{
        margin-top: 30px;
        font-size: 20px;
    }
    .quiz .card-list .card .card-content .card-title {
        font-size: 25px;        
    }
    .quiz .multiple-options {
        grid-gap: 25px;
        gap: 25px;
    }
    .quiz .btn-quiz{
        font: normal normal bold 19px/29px Poppins;
        font-size: 19px;
        height: 48px;
        max-width: 100%;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .quiz .question {
        font-size: 25px;
    }
    .text_apply{
        margin-top: 30px;
        font-size: 18px;
    }
    .quiz .btn-quiz{
        max-width: 100%;
        width: 100%;
    }
}
/********************************** Submit A Charity **********************************/

.submit-charity {
    width: 100%;
    min-height: 963px;
    background: #ffffff 0% 0% no-repeat padding-box;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    padding: 0px 20px;
}

.submit-charity .content {
    z-index: 1;
    position: relative;
    max-width: var(--content-width);
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 40px;
    grid-gap: 40px;
    gap: 40px;
}

.submit-charity .content .cont-left {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.submit-charity .content .cont-left .title {
    margin-top: 134px;
    text-align: left;
    font-family: Filson Soft;
    font-size: 50px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #ffffff;
}

.submit-charity .content .cont-left .description {
    margin-top: 49px;
    max-width: 707px;
    text-align: left;
    font: normal normal normal 26px/43px Informa Pro;
    letter-spacing: 0px;
    color: #ffffff;
}

.submit-charity .content .cont-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.submit-charity .content .cont-right .form-charity {
    margin-top: 60px;
    max-width: 720px;
    width: 100%;
    min-height: 789px;
    background: #144460 0% 0% no-repeat padding-box;
    border-radius: 32px;
    overflow: hidden;
    padding: 44px 54px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-row-gap: 18px;
    row-gap: 18px;
}

.submit-charity .content .cont-right .form-charity .charity-input {
    width: 100%;
    height: 71px;
    background: #F1F2F2 0% 0% no-repeat padding-box;
    border-radius: 12px;
    padding: 22px 26px;
    font: normal normal normal 22px/27px Informa Pro;
    letter-spacing: 0px;
}

.submit-charity .content .cont-right .form-charity .charity-text-area {
    width: 100%;
    height: 167px;
    background: #F1F2F2 0% 0% no-repeat padding-box;
    border-radius: 12px;
    padding: 22px 26px;
    font: normal normal normal 22px/27px Informa Pro;
    letter-spacing: 0px;
}

.submit-charity .content .cont-right .form-charity .charity-input:-ms-input-placeholder, .submit-charity .content .cont-right .form-charity .charity-text-area:-ms-input-placeholder {
    text-align: left;
    font: normal normal normal 22px/27px Informa Pro;
    letter-spacing: 0px;
    color: #707070;
}

.submit-charity .content .cont-right .form-charity .charity-input::placeholder,
.submit-charity .content .cont-right .form-charity .charity-input:-ms-input-placeholder,
.submit-charity .content .cont-right .form-charity .charity-input::-ms-input-placeholder,
.submit-charity .content .cont-right .form-charity .charity-text-area::placeholder,
.submit-charity .content .cont-right .form-charity .charity-text-area:-ms-input-placeholder,
.submit-charity .content .cont-right .form-charity .charity-text-area::-ms-input-placeholder {
    text-align: left;
    font: normal normal normal 22px/27px Informa Pro;
    letter-spacing: 0px;
    color: #707070;
}

.submit-charity .content .cont-right .form-charity .btn-submit {
    width: 100%;
    height: 71px;
    background: #6BC17F 0% 0% no-repeat padding-box;
    border-radius: 12px;
    text-align: center;
    font: normal normal bold 30px/46px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
}


/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .submit-charity .content .cont-left .title {
        font-size: 50px;
    }
    .submit-charity .content .cont-left .description {
        font: normal normal normal 22px/45px Informa Pro;
    }
}

@media (max-width: 1367px) {
    .submit-charity .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .submit-charity .content .cont-right .form-charity {
        max-width: 100%;
    }
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
    .submit-charity {
        padding: 0px 40px;
    }
    .submit-charity .content {
        grid-gap: 0px;
        gap: 0px;
    }
    .submit-charity .content .cont-right {
        justify-content: center;
        align-items: center;
    }
    .submit-charity .content .cont-left .title {
        font-size: 40px;
    }
    .submit-charity .content .cont-left .description {
        font: normal normal normal 20px/45px Informa Pro;
    }
    .submit-charity .content {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .submit-charity {
        padding: 0px 10px;
    }
    .submit-charity .content .cont-right .form-charity .cont-client-name {
        display: flex;
        flex-direction: column;
    }
    .submit-charity .content .cont-left .title,
    .submit-charity .content .cont-left .description {
        padding: 0px 10px;
    }
    .submit-charity .content .cont-right .form-charity {
        margin-top: 20px;
        padding: 40px 40px;
        justify-content: center;
        align-items: center;
        height: auto;
        min-height: auto;
    }
    .submit-charity .content .cont-right .form-charity .charity-input,
    .submit-charity .content .cont-right .form-charity .charity-input.select
    {
      min-height: 60px;
      font: normal normal normal 16px/19px Informa Pro;
    }
    .submit-charity .content .cont-right .form-charity .charity-text-area {
        font: normal normal normal 16px/19px Informa Pro;
    }
    .submit-charity .content .cont-right .form-charity .btn-submit {
        height: 50px;
        font-size: 20px;
    }
    
}

@media (max-width: 585px) {
    .submit-charity .content .cont-right .form-charity {
        margin-top: 20px;
        padding: 40px 20px;
    }
}

@media (max-width: 480px) {
    .submit-charity .content .cont-left .title {
        font-size: 35px;
    }
    .submit-charity .content .cont-left .description {
        font: normal normal normal 20px/45px Informa Pro;
    }
}
:root {
  /* Colors: */
  --container-width: 1920px;
  --content-width: 1640px;
  --color-white-1: #f1f2f2;
  --color-white-2: #e6e7e8;
  --color-green: #6bc17f;
  --color-blue: #144460;
  --color-blue-2: #21445e;
  --color-gray: #707070;

  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-weight-normal: normal;
}

/********************************** Mission **********************************/

.mission .intro {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 40px;
}
.mission .intro .content {
  margin-top: 134px;
  max-width: 1361px;
  width: 100%;
  z-index: 2;
}
.mission .intro .content .title {
  text-align: left;
  font-family: Filson Soft;
  font-size: 50px;
  font-weight: medium;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #ffffff;
}
.mission .intro .content .description {
  margin-top: 49px;
  max-width: 707px;
  width: 100%;
  text-align: left;
  font: normal normal normal 26px/43px Informa Pro;
  letter-spacing: 0px;
  color: #ffffff;
}

.mission .sec-floor-pledge {
  width: 100%;
  min-height: 882px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
.mission .sec-floor-pledge .content {
  display: flex;
  flex-direction: column;
}
.mission .sec-floor-pledge .content .title {
  text-align: center;
  font-size: 50px;
  font-weight: medium;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #144460;
}
.mission .sec-floor-pledge .content .description {
  max-width: 1180px;
  width: 100%;
  margin-top: 75px;
  text-align: center;
  font: normal normal normal 26px/43px Informa Pro;
  letter-spacing: 0px;
  color: #21445e;
  opacity: 1;
}

.mission .sec-list-charities {
  max-width: 100%;
  width: 100%;
  min-height: 1000px;
  background: #e6e7e8 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: flex;
  justify-content: center;
  padding: 40px;
}
.mission .sec-list-charities .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.mission .sec-list-charities .content .title {
  margin-top: 129px;
  text-align: center;
  /* font: normal normal medium 65px/98px Poppins; */
  font-size: 50px;
  font-weight: medium;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #144460;
}
.mission .sec-list-charities .content .description {
  margin-top: 75px;
  max-width: 1180px;
  text-align: center;
  font: normal normal normal 26px/43px Informa Pro;
  letter-spacing: 0px;
  color: #21445e;
}

.mission .brand-link{
  color: #6bc17f;
}

/********************** Media Queries **********************/

@media (max-width: 1367px) {
}

@media (max-width: 1200px) {
  .mission .sec-floor-pledge,
  .mission .sec-list-charities {
    justify-content: center;
    align-items: center;
    padding: 60px 40px;
  }
  .mission .intro .content .title,
  .mission .sec-floor-pledge .content .title,
  .mission .sec-list-charities .content .title {
    margin-top: 0px;
    font-size: 50px;
  }
  .mission .intro .content .description,
  .mission .sec-floor-pledge .content .description,
  .mission .sec-list-charities .content .description {
    font: normal normal normal 24px/43px Informa Pro;
  }
  .mission .sec-list-charities {
    min-height: 882px;
  }
}

@media (max-width: 991px) {
  .mission .intro,
  .mission .sec-floor-pledge,
  .mission .sec-list-charities {
    justify-content: center;
    align-items: center;
    padding: 60px 40px;
  }
  .mission .intro .content {
    margin-top: 0px;
  }
  .mission .sec-floor-pledge .content .title {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .mission .intro .content .title,
  .mission .sec-floor-pledge .content .title,
  .mission .sec-list-charities .content .title {
    margin-top: 0px;
    font-size: 32px;
  }
  .mission .intro .content .description,
  .mission .sec-floor-pledge .content .description,
  .mission .sec-list-charities .content .description {
    font: normal normal normal 20px/43px Informa Pro;
  }
}

@media (max-width: 580px) {
  .mission .intro,
  .mission .sec-floor-pledge,
  .mission .sec-list-charities {
    padding: 60px 20px;
  }
}

.error404_error404__34Xsg {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 550px;
    text-align: center;
}

.error404_content__35AXq {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
}

.error404_title__1d6MB{
    font-size: 45px;
}

.error404_subtitle__1yqz5{
    font-size: 24px;
}

.error404_btn_go_back__2cGjY {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 141px;
    height: 37px;
    background: #6bc17f 0% 0% no-repeat padding-box;
    color: var(--color-blue);
    border-radius: 7px;
    text-align: center;
    font-size: 15px;
    font-weight: var(--unnamed-font-weight-bold);
    letter-spacing: 0px;
    box-shadow: 3px 3px 6px #00000029;
}

@media only screen and (max-width: 767px) {
    .error404_title__1d6MB{
        font-size: 35px;
    }
    .error404_subtitle__1yqz5{
        font-size: 20px;
    }
}
:root {
    /* Colors: */
    --container-width: 1920px;
    --content-width: 1640px;
    --color-white-1: #f1f2f2;
    --color-white-2: #e6e7e8;
    --color-green: #6bc17f;
    --color-blue: #144460;
    --color-blue-2: #21445e;
    --color-gray: #707070;

    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-medium: medium;
    --unnamed-font-weight-bold: bold;
    --unnamed-font-weight-normal: normal;
}

/********************************** Navbar **********************************/
.navbar {
  width: 100%;
  height: 150px;
  padding: 0px 40px;
  color: #707070;
  color: var(--color-gray);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 100;
}
.navbar .btn-menu{
  display: none;
  font-size: 2rem;
  color: #144460;
  color: var(--color-blue);
  cursor: pointer;
}
.navbar .nav-container {
  max-width: 1640px;
  max-width: var(--content-width);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
}
.navbar .nav-container .cont-logo {
  display: flex;
  flex-direction: column;
}
.navbar .nav-container .cont-logo .logo {
  width: 182px;
}
.navbar .nav-container .cont-logo .credits {
  text-align: right;
  font: normal normal normal 18px/24px Informa Pro;
  letter-spacing: 0px;
  color: #707070;
  color: var(--color-gray);
}
.navbar .nav-container .nav-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  grid-gap: 38px;
  gap: 38px;
  font-weight: 500;
}
.navbar .nav-container .nav-menu .nav-item {
  text-align: center;
  font-size: 20px;
  font-weight: medium;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #707070;
  color: var(--color-gray);
}
.navbar .nav-container .nav-menu .btn-take-quiz {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  height: 37px;
  padding: 0px 20px;
  background: #6bc17f 0% 0% no-repeat padding-box;
  color: #144460;
  color: var(--color-blue);
  border-radius: 7px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  font-weight: var(--unnamed-font-weight-bold);
  letter-spacing: 0px;
  box-shadow: 3px 3px 6px #00000029;
}

/********************** Media Queries **********************/

@media (max-width: 1440px) {
  .navbar .nav-container {
    max-width: 1440px;
  }
  
}

@media (max-width: 1367px) {
  .navbar .nav-container {
    max-width: 1200px;
  }
}

@media (max-width: 1200px) {
  .navbar .nav-container {
    max-width: 1100px;
  }
}

@media (max-width: 991px) {
  .navbar {
    height: 100px;
  }
  .navbar .btn-menu{
    display: block;
  }
  .navbar .nav-container .nav-menu {
    padding: 10px 0px 20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 10000;
  }
  .navbar .nav-container .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    background: #fff;

  }
  .navbar .nav-container .nav-menu .nav-item {
    width: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navbar .nav-container .nav-menu .nav-item .nav-links {
    text-align: center;
    padding: 20px;
    width: 100%;
    display: table;
    font-size: 20px;
  }
  .navbar .nav-container .nav-menu .nav-item .nav-links:hover {
    background-color: #144460;
    background-color: var(--color-blue);
    color: #f1f2f2;
    color: var(--color-white-1);
    border-radius: 0;
    transition: all 0.2s ease-out;
  }
  .navbar .nav-container .nav-menu .btn-take-quiz {
    width: 100%;
    padding: 25px 0px;
    font-size: 20px;
  }

}

@media (max-width: 767px) {
  .navbar .nav-container .nav-menu {
    grid-gap: 20px;
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0px 20px;
  }
  .navbar .nav-container .nav-menu .btn-take-quiz,
  .navbar .nav-container .nav-menu .nav-item .nav-links {
    font-size: 16px;
  }
}
/********************************** Footer **********************************/

.footer {
  position: relative;
  width: 100%;
  min-height: 250px;
  background: var(--color-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 40px 20px;
}
.footer .content {
  position: relative;
  max-width: var(--content-width);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 410px;
  grid-gap: 30px;
  gap: 30px;
}
.footer .content .cont-left{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.footer .content .cont-logo {
  max-width: 468px;
  width: 100%;
}
.footer .content .cont-logo .logo {
  max-width: 350px;
  width: 100%;
}
.footer .content .text-logo {
  color: #fff;
  font-size: 55px;
}
.footer .content .cont-links {
  margin-top: 26.5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column-gap: 44px;
  -webkit-column-gap: 44px;
          column-gap: 44px;
  grid-row-gap: 44px;
  row-gap: 44px;
}
.footer .content .cont-links .link {
  font-size: 20px;
  letter-spacing: 0px;
  color: #e6e7e8;
}
.footer .content .cont-right {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .content .btn-submit {
  max-width: 350px;
  width: 100%;
  height: 70px;
  background: #f1f2f2 0% 0% no-repeat padding-box;
  border-radius: 15px;
  text-align: center;
  font: normal normal bold 24px/46px Poppins;
  letter-spacing: 0px;
  color: #144460;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec-copy-right {
  width: 100%;
  height: 33px;
  background: #e6e7e8 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
}
.sec-copy-right .content {
  max-width: 1640px;
  width: 100%;
}
.sec-copy-right .content .credits {
  text-align: left;
  font: normal normal normal 12px/15px Informa Pro;
  letter-spacing: 0px;
  color: #144460;
}

/********************** Media Queries **********************/

@media (max-width: 1440px) {
  .footer .content,
  .sec-copy-right .content {
    max-width: 1367px
  }
}

@media (max-width: 1367px) {
  .footer .content,
  .sec-copy-right .content {
    max-width: 1200px
  }
  .footer .content {
    grid-template-columns: 1fr 350px;
  }
  .footer .content .cont-logo {
    height: auto;
  }
  .footer .content .cont-logo .logo {
    max-width: 350px;
  }
  .footer .content .cont-links .link {
    font-size: 18px;
  }
  .footer .content .btn-submit {
    max-width: 350px;
    font: normal normal bold 22px/46px Poppins;
  }
}

@media (max-width: 1200px) {
  .footer .content,
  .sec-copy-right .content {
    max-width: 1100px
  }
  .footer .content {
    grid-template-columns: 1fr 300px;
  }
  .footer .content .cont-logo {
    height: auto;
  }
  .footer .content .cont-logo,
  .footer .content .cont-logo .logo {
    max-width: 300px;
  }
  .footer .content .cont-links .link {
    font-size: 16px;
  }
  .footer .content .cont-right {
    justify-content: flex-end;
  }
}

@media (max-width: 991px) {
  .footer .content {
    grid-template-columns: 1fr 250px;
  }
  .footer .content .cont-logo {
    height: auto;
  }
  .footer .content .cont-logo .logo {
    max-width: 260px;
    height: auto;
  }
  .footer .content .cont-links {
    /* justify-content: space-between; */
    grid-gap: 30px;
    gap: 30px;
  }
  .footer .content .btn-submit {
    max-width: 250px;
    font: normal normal bold 20px/46px Poppins;
  }
}

@media (max-width: 767px) {
  .sec-copy-right .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer .content .btn-submit {
    max-width: 400px;
    width: 100%;
    font: normal normal bold 20px/46px Poppins;
  }
  .footer .content .cont-left{
    justify-content: center;
    align-items: center;
  }
  .footer .content .cont-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer .content .cont-links {
    flex-direction: column;
  }
  .footer .content .cont-right {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 580px) {
  
  
}

@media (max-width: 480px) {
}

:root {
  /* Colors: */
  --container-width: 1920px;
  --content-width: 1640px;
  --color-white-1: #f1f2f2;
  --color-white-2: #e6e7e8;
  --color-green: #6bc17f;
  --color-blue: #144460;
  --color-blue-2: #21445e;
  --color-gray: #707070;

  /* Font/text values */
  --unnamed-font-family-poppins: Poppins;
  --unnamed-font-family-informa-pro: Informa Pro;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-22: 22px;
  --unnamed-font-size-25: 25px;
  --unnamed-font-size-30: 30px;
  --unnamed-font-size-35: 35px;
  --unnamed-font-size-65: 65px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-27: 27px;
  --unnamed-line-spacing-30: 30px;
  --unnamed-line-spacing-38: 38px;
  --unnamed-line-spacing-43: 43px;
  --unnamed-line-spacing-46: 46px;
  --unnamed-line-spacing-53: 53px;
  --unnamed-line-spacing-98: 98px;
}

/****************************** Globals Styles ******************************/

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background: #fff;
}

ol,
ul {
  list-style: none;
}

a {
  color: inherit;
  /* blue colors for links too */
  text-decoration: none;
  /* no underline */
}

button {
  text-decoration: none;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  background: #6bc17f 0% 0% no-repeat padding-box;
  color: #144460;
  color: var(--color-blue);
  box-shadow: 3px 3px 6px #00000029;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #fff;
  transition: 0.3s ease all;
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

/* Character Styles */
.h1 {
  font-family: Poppins;
  font-family: var(--unnamed-font-family-poppins);
  font-style: normal;
  font-style: var(--unnamed-font-style-normal);
  font-weight: medium;
  font-weight: var(--unnamed-font-weight-medium);
  font-size: 65px;
  font-size: var(--unnamed-font-size-65);
  line-height: 98px;
  line-height: var(--unnamed-line-spacing-98);
  letter-spacing: 0px;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: #144460;
  color: var(--color-blue);
}

.h2 {
  font-family: Informa Pro;
  font-family: var(--unnamed-font-family-informa-pro);
  font-style: normal;
  font-style: var(--unnamed-font-style-normal);
  font-weight: normal;
  font-weight: var(--unnamed-font-weight-normal);
  font-size: 35px;
  font-size: var(--unnamed-font-size-35);
  line-height: 43px;
  line-height: var(--unnamed-line-spacing-43);
  letter-spacing: 0px;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: #21445e;
  color: var(--color-blue-2);
}

.h3 {
  font-family: Poppins;
  font-family: var(--unnamed-font-family-poppins);
  font-style: normal;
  font-style: var(--unnamed-font-style-normal);
  font-weight: medium;
  font-weight: var(--unnamed-font-weight-medium);
  font-size: 35px;
  font-size: var(--unnamed-font-size-35);
  line-height: 53px;
  line-height: var(--unnamed-line-spacing-53);
  letter-spacing: 0px;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: #144460;
  color: var(--color-blue);
}

.h4 {
  font-family: Poppins;
  font-family: var(--unnamed-font-family-poppins);
  font-style: normal;
  font-style: var(--unnamed-font-style-normal);
  font-weight: medium;
  font-weight: var(--unnamed-font-weight-medium);
  font-size: 25px;
  font-size: var(--unnamed-font-size-25);
  line-height: 38px;
  line-height: var(--unnamed-line-spacing-38);
  letter-spacing: 0px;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: #144460;
  color: var(--color-blue);
}

.container {
  max-width: 1920px;
  max-width: var(--container-width);
  width: 100%;
  margin: 0 auto;
}

.content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.msg-error {
  color: #d32f2f;
}

.text-bold{
  font-weight: 600;
}

/********************** React Select **********************/

#react-select-3-input {
  min-height: 30px !important;
}

/********************** React Multi Select **********************/

.rmsc .dropdown-container {
  min-height: 46px;
  outline: none !important;
  border: none !important;
  border-radius: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rmsc .dropdown-heading .dropdown-heading-value {
  padding-left: 30px;
}

.rmsc .gray {
  color: #707070 !important;
}

.dropdown-heading-dropdown-arrow.gray {
  color: #144460 !important;
  color: var(--color-blue) !important;
  margin-right: 11px;
}

/********************** Media Queries **********************/

@media (max-width: 1367px) {}

@media (max-width: 1200px) {}

@media (max-width: 991px) {}

@media (max-width: 767px) {}

@media (max-width: 585px) {}

@media (max-width: 480px) {}
