/********************************** Contact Us **********************************/

.contact-us {
  width: 100%;
  min-height: 963px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  padding: 0px 40px;
}
.contact-us .row{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact-us .content {
  z-index: 1;
  position: relative;
  max-width: var(--content-width);
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding-bottom: 40px;
}
.contact-us .content .cont-left {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.contact-us .content .cont-left .title {
  margin-top: 134px;
  text-align: left;
  /* font: normal normal medium 65px/78px Filson Soft; */
  font-family: Filson Soft;
  font-size: 50px;
  font-weight: var(--unnamed-font-weight-medium);
  letter-spacing: 0px;
  color: #ffffff;
}
.contact-us .content .cont-left .description {
  margin-top: 49px;
  max-width: 707px;
  text-align: left;
  font: normal normal normal 26px/43px Informa Pro;
  letter-spacing: 0px;
  color: #ffffff;
}
.contact-us .content .cont-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.contact-us .content .cont-right .form-contact {
  margin-top: 60px;
  max-width: 720px;
  width: 100%;
  min-height: 789px;
  background: #144460 0% 0% no-repeat padding-box;
  border-radius: 32px;
  overflow: hidden;
  padding: 44px 55px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 18px;
}
.contact-us .content .cont-right .form-contact .cont-client-name {
  width: 100%;
  display: grid;
  grid-template-columns: 249px 341px;
  gap: 18px;
}
.contact-us .content .cont-right .form-contact .name-gap{
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.contact-us .content .cont-right .form-contact .contact-input {
  width: 100%;
  height: 71px;
  background: #f1f2f2 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 22px 26px;
  font: normal normal normal 22px/27px Informa Pro;
  letter-spacing: 0px;
}
.contact-us .content .cont-right .form-contact .contact-input {
  outline: none;
  border: none;
  background-color: red;
  width: 100%;
  height: 71px;
  background: #f1f2f2 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 22px 26px;
  font: normal normal normal 22px/27px Informa Pro;
  letter-spacing: 0px;
}
.contact-us .content .cont-right .form-contact .contact-input.message {
  height: 341px;
}
.contact-us .content .cont-right .form-contact .contact-text-area {
  width: 100%;
  height: 167px;
  background: #f1f2f2 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 22px 26px;
  font: normal normal normal 22px/27px Informa Pro;
  letter-spacing: 0px;
}

.contact-us .content .cont-right .form-contact .contact-input::placeholder,
.contact-us .content .cont-right .form-contact .contact-input:-ms-input-placeholder,
.contact-us .content .cont-right .form-contact .contact-input::-ms-input-placeholder, 
.contact-us .content .cont-right .form-contact .contact-text-area::placeholder,
.contact-us .content .cont-right .form-contact .contact-text-area:-ms-input-placeholder,
.contact-us .content .cont-right .form-contact .contact-text-area::-ms-input-placeholder{
    text-align: left;
    font: normal normal normal 22px/27px Informa Pro;
    letter-spacing: 0px;
    color: #707070;
}
.contact-us .content .cont-right .form-contact .contact-input.select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.contact-us .content .cont-right .form-contact .contact-input.placeholder{
  color: #707070;
}
.contact-us .content .cont-right .form-contact .contact-input.select option{
  width: 100%;
  height: 71px !important;
  color: black;
}
.contact-us .content .cont-right .form-contact select::-ms-expand {
  display: none;
}
.contact-us .content .cont-right .form-contact .btn-submit {
  width: 100%;
  height: 71px;
  background: #6bc17f 0% 0% no-repeat padding-box;
  border-radius: 12px;
  text-align: center;
  font: normal normal bold 30px/46px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .contact-us .content .cont-left .title {
        font-size: 50px;
    }
    .contact-us .content .cont-left .description {
        font: normal normal normal 22px/45px Informa Pro;
    }
}

@media (max-width: 1367px) {
    .contact-us .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .contact-us .content .cont-right .form-contact .cont-client-name {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
    .contact-us {
      padding: 0px 40px;
    }
    .contact-us .content{
      gap: 40px;
    }
    .contact-us .content .cont-right {
        justify-content: center;
        align-items: center;
    }
    .contact-us .content .cont-left .title {
        font-size: 40px;
    }
    .contact-us .content .cont-left .description {
        font: normal normal normal 20px/50px Informa Pro;
    }
    .contact-us .content {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .contact-us {
        padding: 0px 10px;
    }
    .contact-us .content .cont-right .form-contact .cont-client-name {
        display: flex;
        flex-direction: column;
    }
    .contact-us .content .cont-left .title,
    .contact-us .content .cont-left .description {
        padding: 0px 10px;
    }
    .contact-us .content .cont-right .form-contact .contact-input,
    .contact-us .content .cont-right .form-contact .contact-input.select
    {
      min-height: 60px;
      height: 60px;
      padding: 10px 46px 10px 26px;
      font: normal normal normal 16px/19px Informa Pro;
    }
    .contact-us .content .cont-right .form-contact {
      margin-top: 0px;
      padding: 40px 40px;
    }
    .contact-us .content .cont-right .form-contact .btn-submit {
      height: 50px;
      font-size: 20px;
    }
}

@media (max-width: 585px) {
    .contact-us .content .cont-right .form-contact {
        padding: 40px 30px;
    }
    
}

@media (max-width: 480px) {
    .contact-us .content .cont-left .title {
        font-size: 35px;
    }
    .contact-us .content .cont-left .description {
        font: normal normal normal 20px/50px Informa Pro;
    }
}
