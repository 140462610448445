.card {
    max-width: 398px;
    width: 100%;
    height: 511px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 6px #00000029;
    border-radius: 26px;
    overflow: hidden;
}

.card_img {
    height: 281px;
    width: 100%;
    cursor: pointer;
}

.card_body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 10px 10px;
}

.card_title {
    max-width: 307px;
    height: 110px;
    text-align: center;
    /* font: normal normal medium 35px/53px Poppins; */
    font-size: 24px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #144460;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.card_actions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_learn_more {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 226px;
    width: 100%;
    min-height: 60px;
    background: #6bc17f 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 15px;
    text-align: center;
    font: normal normal bold 22px/38px Poppins;
    letter-spacing: 0px;
    color: #144460;
}

.btn_learn_more:hover {
    background-color: #fff;
}

/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .card_title {
        font-size: 24px;        
    }
}

@media (max-width: 1367px) {
    .card {
        height: 400px;
        max-width: 300px;
        width: 100%;
    }
    .card_img {
        height: 200px;
        width: 100%;
    }
    .card_title {
        font-size: 22px;
        height: 90px;
    }
    .card_body {
        padding: 10px 20px 10px 20px;
    }
    .btn_learn_more {
        font-size: 20px;
    }
}

@media (max-width: 1200px) {
    .card_title {
        font-size: 22px;
        height: 80px;
    }
}

@media (max-width: 767px) {
    .card {
        height: auto;
        min-height: auto;
    }
    .card_img{
        height: 123px;
    }
    .card_content {
        min-height: 110px;
    }
    .card_title{
        font: normal normal medium 20px/30px Poppins;
        font-size: 18px;
    }
    .btn_learn_more {
        display: none;
    }
}

@media (max-width: 585px) {
    
}
