.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}

.filter_title{
    text-align: left;
    font: normal normal bold 35px/53px Poppins;
    font-size: 35px;
    letter-spacing: 0px;
    color: #F1F2F2;
}

.filter_question{
    margin-top: 20px;
    text-align: center;
    font: normal normal medium 20px/30px Poppins;
    font-size: 20px;
    letter-spacing: 0px;
    color: #F1F2F2;
}

.cont_actions{
    margin-top: 20px;
    width: 100%;
    min-height: 143px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 13px;
}

.btn_filter{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 319px;
    width: 100%;
    height: 46px;
    background: #F1F2F2 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 10px;
    text-align: center;
    font: normal normal bold 17px/26px Poppins;
    letter-spacing: 0px;
}
.btn_filter.active{
    background: #6BC17F 0% 0% no-repeat padding-box;
}

.btn_apply{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 319px;
    width: 100%;
    height: 46px;
    background: #6BC17F 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 10px;
    text-align: center;
    font: normal normal bold 19px/29px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    cursor: pointer;
}
.btn_apply:hover{
    color: #144460;
}

.btn_reset{
    margin-top: 13px;
    text-align: left;
    font: normal normal bold 15px/23px Poppins;
    letter-spacing: 0px;
    color: #F1F2F2;
    cursor: pointer;
}

.multi_select{
    max-width: 319px;
    width: 100%;
    /* min-height: 46px; */
    border-radius: 10px !important;
}

@media (max-width: 480px){
    .filter_question{
        font-size: 18px;
    }
}

