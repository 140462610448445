/********************************** Submit A Charity **********************************/

.submit-charity {
    width: 100%;
    min-height: 963px;
    background: #ffffff 0% 0% no-repeat padding-box;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    padding: 0px 20px;
}

.submit-charity .content {
    z-index: 1;
    position: relative;
    max-width: var(--content-width);
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 40px;
    gap: 40px;
}

.submit-charity .content .cont-left {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.submit-charity .content .cont-left .title {
    margin-top: 134px;
    text-align: left;
    font-family: Filson Soft;
    font-size: 50px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #ffffff;
}

.submit-charity .content .cont-left .description {
    margin-top: 49px;
    max-width: 707px;
    text-align: left;
    font: normal normal normal 26px/43px Informa Pro;
    letter-spacing: 0px;
    color: #ffffff;
}

.submit-charity .content .cont-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.submit-charity .content .cont-right .form-charity {
    margin-top: 60px;
    max-width: 720px;
    width: 100%;
    min-height: 789px;
    background: #144460 0% 0% no-repeat padding-box;
    border-radius: 32px;
    overflow: hidden;
    padding: 44px 54px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 18px;
}

.submit-charity .content .cont-right .form-charity .charity-input {
    width: 100%;
    height: 71px;
    background: #F1F2F2 0% 0% no-repeat padding-box;
    border-radius: 12px;
    padding: 22px 26px;
    font: normal normal normal 22px/27px Informa Pro;
    letter-spacing: 0px;
}

.submit-charity .content .cont-right .form-charity .charity-text-area {
    width: 100%;
    height: 167px;
    background: #F1F2F2 0% 0% no-repeat padding-box;
    border-radius: 12px;
    padding: 22px 26px;
    font: normal normal normal 22px/27px Informa Pro;
    letter-spacing: 0px;
}

.submit-charity .content .cont-right .form-charity .charity-input::placeholder,
.submit-charity .content .cont-right .form-charity .charity-input:-ms-input-placeholder,
.submit-charity .content .cont-right .form-charity .charity-input::-ms-input-placeholder,
.submit-charity .content .cont-right .form-charity .charity-text-area::placeholder,
.submit-charity .content .cont-right .form-charity .charity-text-area:-ms-input-placeholder,
.submit-charity .content .cont-right .form-charity .charity-text-area::-ms-input-placeholder {
    text-align: left;
    font: normal normal normal 22px/27px Informa Pro;
    letter-spacing: 0px;
    color: #707070;
}

.submit-charity .content .cont-right .form-charity .btn-submit {
    width: 100%;
    height: 71px;
    background: #6BC17F 0% 0% no-repeat padding-box;
    border-radius: 12px;
    text-align: center;
    font: normal normal bold 30px/46px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
}


/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .submit-charity .content .cont-left .title {
        font-size: 50px;
    }
    .submit-charity .content .cont-left .description {
        font: normal normal normal 22px/45px Informa Pro;
    }
}

@media (max-width: 1367px) {
    .submit-charity .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .submit-charity .content .cont-right .form-charity {
        max-width: 100%;
    }
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
    .submit-charity {
        padding: 0px 40px;
    }
    .submit-charity .content {
        gap: 0px;
    }
    .submit-charity .content .cont-right {
        justify-content: center;
        align-items: center;
    }
    .submit-charity .content .cont-left .title {
        font-size: 40px;
    }
    .submit-charity .content .cont-left .description {
        font: normal normal normal 20px/45px Informa Pro;
    }
    .submit-charity .content {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .submit-charity {
        padding: 0px 10px;
    }
    .submit-charity .content .cont-right .form-charity .cont-client-name {
        display: flex;
        flex-direction: column;
    }
    .submit-charity .content .cont-left .title,
    .submit-charity .content .cont-left .description {
        padding: 0px 10px;
    }
    .submit-charity .content .cont-right .form-charity {
        margin-top: 20px;
        padding: 40px 40px;
        justify-content: center;
        align-items: center;
        height: auto;
        min-height: auto;
    }
    .submit-charity .content .cont-right .form-charity .charity-input,
    .submit-charity .content .cont-right .form-charity .charity-input.select
    {
      min-height: 60px;
      font: normal normal normal 16px/19px Informa Pro;
    }
    .submit-charity .content .cont-right .form-charity .charity-text-area {
        font: normal normal normal 16px/19px Informa Pro;
    }
    .submit-charity .content .cont-right .form-charity .btn-submit {
        height: 50px;
        font-size: 20px;
    }
    
}

@media (max-width: 585px) {
    .submit-charity .content .cont-right .form-charity {
        margin-top: 20px;
        padding: 40px 20px;
    }
}

@media (max-width: 480px) {
    .submit-charity .content .cont-left .title {
        font-size: 35px;
    }
    .submit-charity .content .cont-left .description {
        font: normal normal normal 20px/45px Informa Pro;
    }
}