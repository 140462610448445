/********************************** Map **********************************/
.map-img {
    width: 100%;
    height: 500px;
    transition: all 0.3s ease;
}

.map-selected {
    fill: var(--color-green);
}

.map-unselected {
    fill: var(--color-blue);
}

.map-selected:hover,
.map-unselected:hover {
    cursor: pointer;
}

@media (max-width: 991px) {
    .map-img {
        height: 400px;
    }
}

@media (max-width: 767px) {
    .map-img {
        height: auto;
    }
}