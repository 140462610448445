.cont_pr_logos {
    width: 100%;
    min-height: 107px;
    background: #144460 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    display: flex;
    justify-content: center;
    padding: 40px 20px;
}

.content {
    max-width: var(--content-width);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}

.text_pr_logo {
    max-width: 120px;
    width: 100%;
    text-align: center;
    /* font: normal normal medium 20px/30px Poppins; */
    font-size: 20px;
    font-weight: var(--unnamed-font-weight-medium);
    letter-spacing: 0px;
    color: #e6e7e8;
}


/********************** Media Queries **********************/

@media (max-width: 1440px) {
    .content {
        max-width: 1367px;
    }
    .text_pr_logo {
        font-size: 18px;
    }
}

@media (max-width: 1367px) {
    .content {
        max-width: 1200px;
    }
}

@media (max-width: 585px) {
    .content {
        align-items: center;
        justify-content: center;
    }
    .text_pr_logo {
        width: auto;
    }
}

@media (max-width: 480px) {}