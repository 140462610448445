.pagination {
    min-height: 40px;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.link {
    text-align: left;
    font: normal normal normal 30px/37px Filson Soft;
    letter-spacing: 0px;
    color: #21445E;
    cursor: pointer;
}

.link.active {
    text-align: left;
    font: normal normal normal 30px/37px Filson Soft;
    letter-spacing: 0px;
    color: #6BC17F;
}

@media (max-width: 1600px) {
    .link,
    .link.active {
        font-size: 30px;
    }
}

@media (max-width: 1440px) {
    .link,
    .link.active  {
        font-size: 25px;
    }
}

@media (max-width: 1367px) {
    .link,
    .link.active  {
        font-size: 20px;
    }
}

@media (max-width: 991px) {
    .link,
    .link.active  {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .title {
        font-size: 35px;
    }
}

@media (max-width: 585px) {
    .title {
        font-size: 30px;
    }
}