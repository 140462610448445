/********************************** Footer **********************************/

.footer {
  position: relative;
  width: 100%;
  min-height: 250px;
  background: var(--color-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 40px 20px;
}
.footer .content {
  position: relative;
  max-width: var(--content-width);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 410px;
  gap: 30px;
}
.footer .content .cont-left{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.footer .content .cont-logo {
  max-width: 468px;
  width: 100%;
}
.footer .content .cont-logo .logo {
  max-width: 350px;
  width: 100%;
}
.footer .content .text-logo {
  color: #fff;
  font-size: 55px;
}
.footer .content .cont-links {
  margin-top: 26.5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 44px;
  row-gap: 44px;
}
.footer .content .cont-links .link {
  font-size: 20px;
  letter-spacing: 0px;
  color: #e6e7e8;
}
.footer .content .cont-right {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .content .btn-submit {
  max-width: 350px;
  width: 100%;
  height: 70px;
  background: #f1f2f2 0% 0% no-repeat padding-box;
  border-radius: 15px;
  text-align: center;
  font: normal normal bold 24px/46px Poppins;
  letter-spacing: 0px;
  color: #144460;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec-copy-right {
  width: 100%;
  height: 33px;
  background: #e6e7e8 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
}
.sec-copy-right .content {
  max-width: 1640px;
  width: 100%;
}
.sec-copy-right .content .credits {
  text-align: left;
  font: normal normal normal 12px/15px Informa Pro;
  letter-spacing: 0px;
  color: #144460;
}

/********************** Media Queries **********************/

@media (max-width: 1440px) {
  .footer .content,
  .sec-copy-right .content {
    max-width: 1367px
  }
}

@media (max-width: 1367px) {
  .footer .content,
  .sec-copy-right .content {
    max-width: 1200px
  }
  .footer .content {
    grid-template-columns: 1fr 350px;
  }
  .footer .content .cont-logo {
    height: auto;
  }
  .footer .content .cont-logo .logo {
    max-width: 350px;
  }
  .footer .content .cont-links .link {
    font-size: 18px;
  }
  .footer .content .btn-submit {
    max-width: 350px;
    font: normal normal bold 22px/46px Poppins;
  }
}

@media (max-width: 1200px) {
  .footer .content,
  .sec-copy-right .content {
    max-width: 1100px
  }
  .footer .content {
    grid-template-columns: 1fr 300px;
  }
  .footer .content .cont-logo {
    height: auto;
  }
  .footer .content .cont-logo,
  .footer .content .cont-logo .logo {
    max-width: 300px;
  }
  .footer .content .cont-links .link {
    font-size: 16px;
  }
  .footer .content .cont-right {
    justify-content: flex-end;
  }
}

@media (max-width: 991px) {
  .footer .content {
    grid-template-columns: 1fr 250px;
  }
  .footer .content .cont-logo {
    height: auto;
  }
  .footer .content .cont-logo .logo {
    max-width: 260px;
    height: auto;
  }
  .footer .content .cont-links {
    /* justify-content: space-between; */
    gap: 30px;
  }
  .footer .content .btn-submit {
    max-width: 250px;
    font: normal normal bold 20px/46px Poppins;
  }
}

@media (max-width: 767px) {
  .sec-copy-right .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer .content .btn-submit {
    max-width: 400px;
    width: 100%;
    font: normal normal bold 20px/46px Poppins;
  }
  .footer .content .cont-left{
    justify-content: center;
    align-items: center;
  }
  .footer .content .cont-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer .content .cont-links {
    flex-direction: column;
  }
  .footer .content .cont-right {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 580px) {
  
  
}

@media (max-width: 480px) {
}
