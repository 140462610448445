.error404 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 550px;
    text-align: center;
}

.content {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.title{
    font-size: 45px;
}

.subtitle{
    font-size: 24px;
}

.btn_go_back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 141px;
    height: 37px;
    background: #6bc17f 0% 0% no-repeat padding-box;
    color: var(--color-blue);
    border-radius: 7px;
    text-align: center;
    font-size: 15px;
    font-weight: var(--unnamed-font-weight-bold);
    letter-spacing: 0px;
    box-shadow: 3px 3px 6px #00000029;
}

@media only screen and (max-width: 767px) {
    .title{
        font-size: 35px;
    }
    .subtitle{
        font-size: 20px;
    }
}